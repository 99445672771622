import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Dialog } from '@headlessui/react';
import moment from 'moment';

import { Modal, BasicButton } from '../../../components/Base';
import { SERVICE_STATUS_STRING } from '../../../constants/data';

function PhoneServiceDetail(props, ref) {
  const [isVisible, setIsVisible] = useState(false);
  const [phoneService, setPhoneService] = useState(null);

  useImperativeHandle(ref, () => ({ show }));

  const show = (data) => {
    setPhoneService(data);
    setIsVisible(true);
  };
  const close = () => {
    setIsVisible(false);
    setPhoneService(null);
  };

  return (
    <Modal
      isVisible={isVisible}
      size="large"
      onClose={close}
      ModalContent={
        <div className="sm:flex sm:items-start">
          <div className="w-full mt-3 text-center sm:mt-0 sm:text-left">
            <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
              {`Service #${phoneService?.service_no}`}
            </Dialog.Title>
            <div className="mt-5">
              <div className="relative w-full mb-2">
                <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-1 md:gap-4 items-center">
                  <div className="block uppercase text-gray-700 text-xs font-bold">Service No</div>
                  <div className="col-span-2 xl:col-span-3">{phoneService?.service_no}</div>
                </div>
              </div>

              <div className="relative w-full mb-2">
                <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-1 md:gap-4 items-center">
                  <div className="block uppercase text-gray-700 text-xs font-bold">
                    Tanggal Service
                  </div>
                  <div className="col-span-2 xl:col-span-3">
                    {moment(phoneService?.time).format('DD MMM YYYY HH:mm')}
                  </div>
                </div>
              </div>

              <div className="relative w-full mb-2">
                <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-1 md:gap-4 items-center">
                  <div className="block uppercase text-gray-700 text-xs font-bold">Customer</div>
                  <div className="col-span-2 xl:col-span-3">
                    {`${phoneService?.customer_name} (Ph: ${phoneService?.customer_phone})`}
                  </div>
                </div>
              </div>

              <div className="relative w-full mb-2">
                <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-1 md:gap-4 items-center">
                  <div className="block uppercase text-gray-700 text-xs font-bold">Status</div>
                  <div
                    className="col-span-2 xl:col-span-3"
                    dangerouslySetInnerHTML={{
                      __html: SERVICE_STATUS_STRING[phoneService?.status],
                    }}></div>
                </div>
              </div>

              <div className="relative w-full mb-5">
                <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-1 md:gap-4 items-center">
                  <div className="block uppercase text-gray-700 text-xs font-bold">Deskripsi</div>
                  <div className="col-span-2 xl:col-span-3">{phoneService?.description}</div>
                </div>
              </div>

              {/* SERVICE LOG */}
              <section className="mt-8 text-left">
                <div className="flex flex-wrap">
                  {phoneService?.phone_service_logs?.map((log, index) => (
                    <div
                      key={log.id}
                      className={`flex relative ${index === 0 ? 'pt-6' : ''} pb-6 items-center`}>
                      <div className="h-full w-5 absolute inset-0 flex items-center justify-center">
                        <div className="h-full w-0.5 bg-gray-200 pointer-events-none"></div>
                      </div>
                      <div className="flex-shrink-0 w-5 h-5 rounded-full inline-flex items-center justify-center bg-indigo-500 text-white relative z-10 title-font font-medium text-xs">
                        {index + 1}
                      </div>
                      <div className="flex-grow pl-5 flex items-center items-start flex-row">
                        <div className="flex-grow">
                          <h2 className="font-bold text-sm">{log.time}</h2>
                          <p className="-mt-1 text-gray-400 leading-relaxed">{log.description}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </section>
            </div>
          </div>
        </div>
      }
      ModalButton={<BasicButton text="Close" textClass="text-white text-xs mr-2" onClick={close} />}
    />
  );
}

export default forwardRef(PhoneServiceDetail);
