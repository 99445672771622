import React, { useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';

import $ from 'jquery';

import FilterRangeDate from '../Filter/FilterRangeDate';
import OrderDetail from './Detail';
import OrderRefund from './Refund';
import { SubmitButton } from '../../components/Base';
import { API_URL, DATATABLES_DEFAULT_CONFIG } from '../../constants/env';
import Utils from '../../helpers/utils';

export default function Order() {
  const orderDetailRef = useRef();
  const orderRefundRef = useRef();

  const { result: authenticatedUser } = useSelector((state) => state.authenticatedUser);
  const { filterDate } = useSelector((state) => state.filter);
  const { refresh } = useSelector((state) => state.order);

  useEffect(() => {
    initializeDataTables(filterDate?.startDate, filterDate?.endDate);
  }, [refresh, filterDate]);

  const initializeDataTables = (startDate, endDate) => {
    $('#orderDataTable').DataTable().destroy();
    $('#orderDataTable').DataTable({
      ...DATATABLES_DEFAULT_CONFIG,
      ajax: {
        url: `${API_URL}/order?source=datatables&paid=1&refunded=0&s=${startDate}&e=${endDate}`,
        dataSrc: 'data',
        beforeSend: (xhr) =>
          xhr.setRequestHeader('Authorization', `Bearer ${authenticatedUser.token}`),
      },
      columns: [
        { data: 'order_time', render: (data) => moment(data).format('DD MMM YYYY HH:mm') },
        {
          data: 'order_no',
          render: (data) => `#${data}`,
        },
        {
          data: 'grand_total',
          render: (data) => Utils.numberFormat(data, 2),
        },
        {
          data: 'first_name',
          render: (data, type, row) => `${data} ${row.last_name}`,
        },
        {
          data: 'warehouse.name',
          name: 'warehouse.name',
        },
        { data: 'shipping_type' },
        {
          data: null,
          searchable: false,
          sortable: false,
          render: (data, type, row) => Utils.generateOrderStatus(row),
        },
        {
          data: 'id',
          searchable: false,
          sortable: false,
          createdCell: (cell, cellData, rowData) =>
            ReactDOM.render(
              <div>
                <SubmitButton
                  color="green"
                  textClass="text-white text-xs"
                  paddingClass="px-3 py-2 mr-1"
                  text="View"
                  onClick={() => onPressView(rowData)}
                />

                {rowData.is_refunded === 0 && (
                  <SubmitButton
                    color="red"
                    textClass="text-white text-xs"
                    paddingClass="px-3 py-2 mr-1"
                    text="Refund"
                    onClick={() => onPressRefund(rowData)}
                  />
                )}
              </div>,
              cell,
            ),
        },
      ],
      order: [[0, 'desc']],
    });
  };

  const onPressView = (data) => orderDetailRef.current.show(data);
  const onPressRefund = (data) => orderRefundRef.current.showFormWithInitialData(data);

  return (
    <>
      <div className="w-full md:w-1/2 mb-5">
        <FilterRangeDate />
      </div>

      <div className="relative flex flex-col min-w-0 w-full h-full bg-white mb-8 shadow-lg rounded-xl">
        {/* Header */}
        <div className="flex flex-wrap items-center py-3 border-b">
          <div className="px-4 flex-1">
            <h3 className="font-semibold text-gray-800 pl-2">Order</h3>
          </div>
          <div className="px-4">
            <a
              href={`${API_URL}/order/export/excel?s=${filterDate.startDate}&e=${filterDate.endDate}`}
              target="_blank"
              rel="noreferrer">
              <SubmitButton textClass="text-white text-xs" text="Export Excel" />
            </a>
            <OrderDetail ref={orderDetailRef} />
            <OrderRefund ref={orderRefundRef} />
          </div>
        </div>

        {/* Table Content */}
        <div className="block w-full overflow-x-auto p-6">
          {/* Projects table */}
          <table id="orderDataTable" className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 border-l-0 border-r-0 text-xs uppercase font-semibold text-left">
                  Order Time
                </th>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 border-l-0 border-r-0 text-xs uppercase font-semibold text-left">
                  Order No
                </th>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 border-l-0 border-r-0 text-xs uppercase font-semibold text-left">
                  Total
                </th>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 border-l-0 border-r-0 text-xs uppercase font-semibold text-left">
                  Customer
                </th>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 border-l-0 border-r-0 text-xs uppercase font-semibold text-left">
                  Store
                </th>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 border-l-0 border-r-0 text-xs uppercase font-semibold text-left">
                  Shipping
                </th>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 border-l-0 border-r-0 text-xs uppercase font-semibold text-left">
                  Status
                </th>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 border-l-0 border-r-0 text-xs uppercase font-semibold text-left w-40"></th>
              </tr>
            </thead>
            <tbody></tbody>
          </table>
        </div>
      </div>
    </>
  );
}
