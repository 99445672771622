import React, { useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useSelector, useDispatch } from 'react-redux';

import $ from 'jquery';

import ProductStock from './Stock';
import ProductCreate from './Create';
import { DeleteButton, SubmitButton } from '../../components/Base';
import { API_URL, DATATABLES_DEFAULT_CONFIG } from '../../constants/env';
import { STATUS_STRING } from '../../constants/data';
import Utils from '../../helpers/utils';
import { deleteProduct } from '../../actions/product';

export default function Product() {
  const productStockRef = useRef();
  const productFormRef = useRef();

  const { result: authenticatedUser } = useSelector((state) => state.authenticatedUser);
  const { refresh } = useSelector((state) => state.product);
  const dispatch = useDispatch();

  useEffect(() => {
    initializeDataTables();
  }, [refresh]);

  const initializeDataTables = () => {
    $('#productDataTable').DataTable().destroy();
    $('#productDataTable').DataTable({
      ...DATATABLES_DEFAULT_CONFIG,
      ajax: {
        url: `${API_URL}/product?source=datatables`,
        dataSrc: 'data',
        beforeSend: (xhr) =>
          xhr.setRequestHeader('Authorization', `Bearer ${authenticatedUser.token}`),
      },
      columns: [
        { data: 'sku' },
        { data: 'name' },
        {
          data: 'product_category.name',
          name: 'productCategory.name',
        },
        {
          data: 'product_variants.length',
          searchable: false,
          sortable: false,
          render: (data) => `${data} Variasi`,
        },
        {
          data: 'price',
          render: (data) => Utils.numberFormat(data, 2),
        },
        {
          data: 'total_stock',
          searchable: false,
          sortable: false,
        },
        {
          data: 'is_featured',
          render: (data) => (data === 1 ? '✓' : ''),
        },
        {
          data: 'status',
          render: (data) => STATUS_STRING[data],
        },
        {
          data: 'id',
          searchable: false,
          sortable: false,
          createdCell: (cell, cellData, rowData) =>
            ReactDOM.render(
              <div>
                <SubmitButton
                  color="green"
                  textClass="text-white text-xs"
                  paddingClass="px-3 py-2 mr-1"
                  text="Stock"
                  onClick={() => onPressStock(rowData)}
                />
                <SubmitButton
                  color="blue"
                  textClass="text-white text-xs"
                  paddingClass="px-3 py-2 mr-1"
                  text="Edit"
                  onClick={() => onPressEdit(rowData)}
                />
                <DeleteButton
                  color="red"
                  textClass="text-white text-xs"
                  paddingClass="px-3 py-2"
                  onClick={() => onPressDelete(rowData)}
                />
              </div>,
              cell,
            ),
        },
      ],
    });
  };

  const onPressCreate = () => productFormRef.current.showForm();
  const onPressStock = (data) => productStockRef.current.showFormWithInitialData(data);
  const onPressEdit = (data) => productFormRef.current.showFormWithInitialData(data);
  const onPressDelete = (data) => dispatch(deleteProduct(data.id));

  return (
    <>
      <div className="relative flex flex-col min-w-0 w-full h-full bg-white mb-8 shadow-lg rounded-xl">
        {/* Header */}
        <div className="flex flex-wrap items-center py-3 border-b">
          <div className="px-4 flex-1">
            <h3 className="font-semibold text-gray-800 pl-2">Produk</h3>
          </div>
          <div className="px-4">
            <SubmitButton
              textClass="text-white text-xs"
              text="Tambah Produk"
              onClick={onPressCreate}
            />
            <ProductStock ref={productStockRef} />
            <ProductCreate ref={productFormRef} />
          </div>
        </div>

        {/* Table Content */}
        <div className="block w-full overflow-x-auto p-6">
          {/* Projects table */}
          <table
            id="productDataTable"
            className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-semibold text-left">
                  SKU
                </th>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-semibold text-left w-40">
                  Nama
                </th>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-semibold text-left">
                  Kategori
                </th>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-semibold text-left">
                  Variasi
                </th>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-semibold text-left">
                  Harga
                </th>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-semibold text-left">
                  Stock
                </th>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-semibold text-left">
                  Featured
                </th>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-semibold text-left">
                  Status
                </th>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-semibold text-left w-60"></th>
              </tr>
            </thead>
            <tbody></tbody>
          </table>
        </div>
      </div>
    </>
  );
}
