import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import { TextInput, SubmitButton } from '../../../components/Base';
import { submitSignIn } from '../../../actions/auth';

const appLogo = require('../../../assets/img/bstore.png');

export default function Login() {
  const history = useHistory();

  const [isSubmittingForm, setIsSubmittingForm] = useState(false);
  const { register, errors, handleSubmit } = useForm();

  const dispatch = useDispatch();

  const onSubmitForm = (data) => {
    setIsSubmittingForm(true);
    dispatch(submitSignIn(data)).then(
      () => history.replace('/dashboard'),
      () => {
        setIsSubmittingForm(false);
      },
    );
  };

  return (
    <>
      <div className="container mx-auto px-4 h-full">
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full lg:w-4/12 px-4">
            <div className="flex justify-center">
              <img src={appLogo} alt="BStore" className="h-16" />
            </div>

            <div className="mt-10 relative flex flex-col min-w-0 break-words w-full bg-white shadow-lg rounded-lg border-0">
              <div className="flex-auto px-5 py-5">
                <h4 className="text-2xl font-bold mb-8">Sign In</h4>

                <form onSubmit={handleSubmit(onSubmitForm)}>
                  <TextInput
                    title="Phone"
                    inputRef={register({ required: true })}
                    type="phone"
                    name="phone"
                    errorMessage={
                      errors.phone && 'Kolom Phone harus diisi dengan Phone No yang valid'
                    }
                  />

                  <TextInput
                    title="Password"
                    inputRef={register({ required: true })}
                    type="password"
                    name="password"
                    errorMessage={errors.password && 'Kolom Password harus diisi'}
                  />

                  <div className="text-center mt-10">
                    <SubmitButton
                      additionalClass="w-full"
                      type="submit"
                      text="Sign In"
                      isLoading={isSubmittingForm}
                    />
                  </div>
                </form>
              </div>
            </div>

            <div className="mt-10 text-center text-sm">
              Copyright &copy; {moment().format('Y')}. BSTORE. All Rights Reserved.
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
