import React, { forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import { Dialog } from '@headlessui/react';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import {
  Modal,
  InlineTextInput,
  InlineTextArea,
  InlineSelect,
  BasicButton,
  SubmitButton,
} from '../../../components/Base';
import Collapsible from '../../../components/Collapsible';

import { fetchTechnician } from '../../../actions/technician';
import { submitPhoneService, updatePhoneService } from '../../../actions/phoneService';

function PhoneServiceCreate(props, ref) {
  const { register, errors, handleSubmit, setValue } = useForm();

  const [isVisible, setIsVisible] = useState(false);
  const [initialPhoneService, setInitialPhoneService] = useState(null);
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);

  const { result: technicians } = useSelector((state) => state.technician);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchTechnician());
  }, []);

  useImperativeHandle(ref, () => ({
    showForm,
    showFormWithInitialData,
  }));

  useEffect(() => {
    if (initialPhoneService) {
      setTimeout(() => {
        setValue('pic', initialPhoneService.pic);
        setValue('phone_brand', initialPhoneService.phone_brand);
        setValue('phone_model', initialPhoneService.phone_model);
        setValue('customer_name', initialPhoneService.customer_name);
        setValue('customer_phone', initialPhoneService.customer_phone);
        setValue('description', initialPhoneService.description);
        setValue('technician_id', initialPhoneService.technician_id);
        setValue('status', initialPhoneService.status);
      }, 100);
    }
  }, [initialPhoneService]);

  const showForm = () => setIsVisible(true);
  const closeForm = () => {
    setIsVisible(false);
    setInitialPhoneService(null);
    setIsSubmittingForm(false);
  };

  const showFormWithInitialData = (data) => {
    setInitialPhoneService(data);
    showForm();
  };

  const onSubmitForm = (data) => {
    setIsSubmittingForm(true);

    const selectedTechnician = technicians.find((t) => t.id === Number(data.technician_id));
    if (selectedTechnician) {
      data.technician_nik = selectedTechnician.nik;
      data.technician_name = selectedTechnician.name;
    }

    if (initialPhoneService) {
      dispatch(updatePhoneService(initialPhoneService.id, data))
        .then(() => closeForm())
        .catch(() => setIsSubmittingForm(false));
    } else {
      dispatch(submitPhoneService(data))
        .then(() => closeForm())
        .catch(() => setIsSubmittingForm(false));
    }
  };

  return (
    <Modal
      isVisible={isVisible}
      size="large"
      ModalContent={
        <div className="sm:flex sm:items-start">
          <div className="w-full mt-3 text-center sm:mt-0 sm:text-left">
            <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
              {initialPhoneService ? 'Ubah Service' : 'Tambah Service Baru'}
            </Dialog.Title>
            <div className="mt-5">
              <form id="createForm" onSubmit={handleSubmit(onSubmitForm)}>
                <Collapsible title="Service Information">
                  {initialPhoneService && (
                    <>
                      <div className="relative w-full mb-2">
                        <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-1 md:gap-4 items-center">
                          <div className="block uppercase text-gray-700 text-xs font-bold">
                            Service No
                          </div>
                          <div className="col-span-2 xl:col-span-3">
                            #{initialPhoneService.service_no}
                          </div>
                        </div>
                      </div>

                      <div className="relative w-full mb-5">
                        <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-1 md:gap-4 items-center">
                          <div className="block uppercase text-gray-700 text-xs font-bold">
                            Tanggal Service
                          </div>
                          <div className="col-span-2 xl:col-span-3">
                            {moment(initialPhoneService.time).format('DD MMM YYYY HH:mm')}
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  <InlineTextInput
                    type="text"
                    title="PIC"
                    inputRef={register({ required: true })}
                    name="pic"
                    errorMessage={errors.pic && 'Kolom PIC harus diisi'}
                  />

                  <InlineTextInput
                    type="text"
                    title="Phone Brand"
                    inputRef={register({ required: true })}
                    name="phone_brand"
                    errorMessage={errors.phone_brand && 'Kolom Phone Brand harus diisi'}
                  />

                  <InlineTextInput
                    type="text"
                    title="Phone Model"
                    inputRef={register({ required: true })}
                    name="phone_model"
                    errorMessage={errors.phone_model && 'Kolom Phone Model harus diisi'}
                  />

                  <InlineTextInput
                    type="text"
                    title="Nama Customer"
                    inputRef={register({ required: true })}
                    name="customer_name"
                    errorMessage={errors.customer_name && 'Kolom Nama Customer harus diisi'}
                  />

                  <InlineTextInput
                    type="text"
                    title="No HP Customer"
                    inputRef={register({ required: true })}
                    name="customer_phone"
                    errorMessage={errors.customer_phone && 'Kolom No HP Customer harus diisi'}
                  />

                  <InlineTextArea
                    title="Deskripsi Service"
                    inputRef={register({ required: true })}
                    name="description"
                    errorMessage={errors.description && 'Kolom Description harus diisi'}
                  />
                </Collapsible>

                <Collapsible title="Teknisi">
                  <InlineSelect
                    title="Teknisi"
                    inputRef={register()}
                    name="technician_id"
                    options={technicians.map((technician) => (
                      <option
                        key={technician.id}
                        value={
                          technician.id
                        }>{`${technician.name} (NIK: ${technician.nik})`}</option>
                    ))}
                  />

                  {initialPhoneService && initialPhoneService.status > 0 && (
                    <InlineSelect
                      title="Status Pengerjaan"
                      inputRef={register({ required: true })}
                      name="status"
                      options={[
                        <option key={1} value={1}>
                          Service sedang dikerjakan
                        </option>,
                        <option key={2} value={2}>
                          Service telah Selesai
                        </option>,
                      ]}
                      errorMessage={errors.status && 'Kolom Status harus dipilih'}
                    />
                  )}
                </Collapsible>
              </form>
            </div>
          </div>
        </div>
      }
      ModalButton={
        <>
          <SubmitButton
            type="submit"
            form="createForm"
            text="Submit"
            textClass="text-white text-xs"
            isLoading={isSubmittingForm}
          />
          <BasicButton text="Cancel" textClass="text-white text-xs mr-2" onClick={closeForm} />
        </>
      }
    />
  );
}

export default forwardRef(PhoneServiceCreate);
