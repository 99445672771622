import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import Collapsible from '../../../components/Collapsible';
import { SubmitButton } from '../../../components/Base';
import Utils from '../../../helpers/utils';
import { API_URL } from '../../../constants/env';

import { updateOrder } from '../../../actions/order';

export default function ShippingTable({ order }) {
  const { register, errors, handleSubmit } = useForm();

  const dispatch = useDispatch();

  const [isSubmittingForm, setIsSubmittingForm] = useState(false);

  const onSubmitForm = (data) => {
    setIsSubmittingForm(true);

    dispatch(updateOrder(order.id, data))
      .then(() => setIsSubmittingForm(false))
      .catch(() => setIsSubmittingForm(false));
  };

  return (
    <Collapsible title="Informasi Pengiriman">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <h2 className="font-bold text-lg">Shipping Address</h2>
          <div className="mt-1">
            <p>{order.street_address}</p>
            <p>{`${order.district}, ${order.city}`}</p>
            <p>{`${order.province} ${order.zip_code}`}</p>
          </div>
          <div>{`${order.first_name} ${order.last_name} (Ph: ${order.phone})`}</div>
        </div>

        <div>
          <h2 className="font-bold text-lg">Shipping Method</h2>
          <div className="mt-1">
            <div className="-ml-2 px-2 py-1 bg-gray-100 rounded">
              <span className="uppercase">{order.shipping_type} : </span>
              <span>{order.shipping_type === 'Self Pickup' ? order.warehouse?.name : ''} </span>
              <span>
                {order.shipping_method_name} {order.shipping_method_detail}
              </span>
            </div>
            <div className="mt-1">
              <p>{`Shipping Fee: Rp ${Utils.numberFormat(order.total_shipping_fee, 0)}`}</p>
              <p>{`Berat: ${Utils.numberFormat(order.total_weight, 0)} gram`}</p>
              <p className="font-bold">{`No Resi: ${order.resi_no ? order.resi_no : '-'}`}</p>
            </div>

            <div className="mt-2">
              <a
                href={`${API_URL}/order/${order.hash}/shipping-label`}
                target="_blank"
                rel="noreferrer"
                className="block py-2 px-6 rounded transition-all bg-purple-600 hover:bg-purple-700
                  text-gray-100 hover:text-white text-sm uppercase font-medium text-center">
                Print Shipping Label
              </a>
            </div>
          </div>
        </div>
      </div>

      {order.is_refunded === 0 && order.is_shipped === 0 && (
        <div className="mt-4 pt-4 border-t">
          <h4 className="font-bold mb-1">Update No Resi / Invoice</h4>

          <form onSubmit={handleSubmit(onSubmitForm)}>
            <div className="flex">
              <div className="w-full sm:w-2/3 md:w-3/4">
                <input
                  ref={register({ required: true })}
                  type="text"
                  className="w-full border rounded px-2 py-2"
                  placeholder="No Resi / Invoice"
                  name="resi_no"
                />
                {errors.resi_no && (
                  <div className="mt-1 text-xs text-red-500">
                    Kolom No Resi / Invoice harus diisi
                  </div>
                )}
              </div>
              <div className="w-full sm:w-1/3 md:w-1/4 pl-0 sm:pl-2">
                <SubmitButton
                  type="submit"
                  text="Update"
                  textClass="text-white text-xs"
                  additionalClass="w-full"
                  isLoading={isSubmittingForm}
                />
              </div>
            </div>
          </form>
        </div>
      )}
    </Collapsible>
  );
}
