import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import Collapsible from '../../../components/Collapsible';
import { ConfirmationButton } from '../../../components/Base';
import Utils from '../../../helpers/utils';
import { APP_URL } from '../../../constants/env';

import { acceptPaymentConfirmation, rejectPaymentConfirmation } from '../../../actions/order';

export default function PaymentTable({ order }) {
  const cellClass = 'px-2 py-2 border-b text-sm';

  const dispatch = useDispatch();

  const [isSubmittingForm, setIsSubmittingForm] = useState(false);

  const onClickAccept = (payment) => {
    setIsSubmittingForm(true);

    dispatch(acceptPaymentConfirmation({ order_payment_id: payment.id }))
      .then(() => setIsSubmittingForm(false))
      .catch(() => setIsSubmittingForm(false));
  };

  const onClickReject = (payment) => {
    setIsSubmittingForm(true);

    dispatch(rejectPaymentConfirmation({ order_payment_id: payment.id }))
      .then(() => setIsSubmittingForm(false))
      .catch(() => setIsSubmittingForm(false));
  };

  return (
    <Collapsible title="Pembayaran">
      <table className="items-center w-full bg-transparent border-collapse">
        <thead>
          <tr>
            <th className="p-2 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-bold text-left">
              Metode Pembayaran
            </th>
            <th className="p-2 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-bold text-left">
              Total
            </th>
            <th className="p-2 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-bold text-left">
              Status
            </th>
            <th className="p-2 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-bold text-left">
              Confirmed
            </th>
            <th className="p-2 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-bold text-left">
              Bukti
            </th>
            <th className="p-2 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-bold text-left"></th>
          </tr>
        </thead>

        <tbody>
          {order.order_payments.map((payment) => (
            <tr key={payment.id}>
              <td className={cellClass}>{payment.payment_method_name}</td>
              <td className={cellClass}>{Utils.numberFormat(payment.total, 0)}</td>
              <td className={cellClass}>
                {payment.is_paid === -1 && (
                  <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                    Expired
                  </span>
                )}
                {payment.is_paid === 0 && (
                  <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
                    Pending
                  </span>
                )}
                {payment.is_paid === 1 && (
                  <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                    Paid
                  </span>
                )}
              </td>
              <td className={cellClass}>
                {payment.is_confirmed === 1 && <span className="text-green-600">✓</span>}
              </td>
              <td className={cellClass}>
                <a
                  href={`${APP_URL}/${payment.payment_receipt}`}
                  target="_blank"
                  rel="noreferrer"
                  className="text-blue-500 text-xs">
                  {payment.payment_date}
                </a>
              </td>
              <td className={cellClass}>
                {order.is_refunded === 0 &&
                  !payment.payment_gateway_id &&
                  payment.is_confirmed === 0 && (
                    <>
                      <ConfirmationButton
                        color="green"
                        text="Accept"
                        textClass="text-white text-xs"
                        paddingClass="px-2 py-2 mr-1"
                        title="Accept Confirmation"
                        description="Are you sure want to Accept the Payment? This action cannot be undone."
                        isLoading={isSubmittingForm}
                        onClick={() => onClickAccept(payment)}
                      />
                      <ConfirmationButton
                        color="red"
                        text="Reject"
                        textClass="text-white text-xs"
                        paddingClass="px-2 py-2 mr-1"
                        title="Reject Confirmation"
                        description="Are you sure want to Reject the Payment? This action cannot be undone."
                        isLoading={isSubmittingForm}
                        onClick={() => onClickReject(payment)}
                      />
                    </>
                  )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Collapsible>
  );
}
