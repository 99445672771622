import Swal from 'sweetalert2';
import {
  FETCH_WAREHOUSE,
  SUBMIT_WAREHOUSE,
  DELETE_WAREHOUSE,
  REFRESH_WAREHOUSE_DATATABLES,
} from './_types';
import { API_URL, API_RESPONSE_STATUS, API_RESPONSE_MESSAGE, API_RESPONSE } from '../constants/env';
import { authApiHeader } from '../helpers/apiHeader';

export const fetchWarehouse = () => {
  return (dispatch) => {
    return fetch(`${API_URL}/warehouse`, {
      method: 'GET',
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            dispatch({ type: FETCH_WAREHOUSE, result: response.data });
            break;

          case API_RESPONSE.FAILED:
          default:
            dispatch({ type: FETCH_WAREHOUSE, result: [] });
            Swal.fire('Fetch Warehouse Failed', response[API_RESPONSE_MESSAGE], 'error');
        }
      });
  };
};

export const refreshWarehouseDatatables = () => {
  return (dispatch) => {
    dispatch({ type: REFRESH_WAREHOUSE_DATATABLES });
  };
};

/**
 * @param data
 * @param data      name, street_address, district_id, district, city_id, city, province_id, province
 *                  zip_code, pic, pic_phone, ipay88_merchant_code, ipay88_merchant_key
 */
export const submitWarehouse = (data) => {
  return (dispatch) => {
    return fetch(`${API_URL}/warehouse`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            Swal.fire('Submit Success', response[API_RESPONSE_MESSAGE], 'success');
            dispatch({ type: SUBMIT_WAREHOUSE, result: response });
            break;

          case API_RESPONSE.FAILED:
          default:
            Swal.fire('Submit Failed', response[API_RESPONSE_MESSAGE], 'error');
            return Promise.reject(response[API_RESPONSE_MESSAGE]);
        }
      })
      .then(() => dispatch(refreshWarehouseDatatables()))
      .then(() => dispatch(fetchWarehouse()));
  };
};

/**
 * @param id
 * @param data      name, street_address, district_id, district, city_id, city, province_id, province
 *                  zip_code, pic, pic_phone, ipay88_merchant_code, ipay88_merchant_key
 */
export const updateWarehouse = (id, data) => {
  return (dispatch) => {
    return fetch(`${API_URL}/warehouse/${id}`, {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            Swal.fire('Submit Success', response[API_RESPONSE_MESSAGE], 'success');
            dispatch({ type: SUBMIT_WAREHOUSE, result: response });
            break;

          case API_RESPONSE.FAILED:
          default:
            Swal.fire('Submit Failed', response[API_RESPONSE_MESSAGE], 'error');
            return Promise.reject(response[API_RESPONSE_MESSAGE]);
        }
      })
      .then(() => dispatch(refreshWarehouseDatatables()))
      .then(() => dispatch(fetchWarehouse()));
  };
};

/**
 * @param id
 */
export const deleteWarehouse = (id) => {
  return (dispatch) => {
    return fetch(`${API_URL}/warehouse/${id}`, {
      method: 'DELETE',
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            Swal.fire('Delete Success', response[API_RESPONSE_MESSAGE], 'success');
            dispatch({ type: DELETE_WAREHOUSE, result: response });
            break;

          case API_RESPONSE.FAILED:
          default:
            Swal.fire('Delete Failed', response[API_RESPONSE_MESSAGE], 'error');
            return Promise.reject(response[API_RESPONSE_MESSAGE]);
        }
      })
      .then(() => dispatch(refreshWarehouseDatatables()))
      .then(() => dispatch(fetchWarehouse()));
  };
};
