import { FILTER_BY_DATE, FILTER_BY_WAREHOUSE } from './_types';
import moment from 'moment';

export const filterByDate = (startDate, endDate) => {
  return (dispatch) => {
    const date = moment(startDate).format('YYYY-MM-DD');
    dispatch({ type: FILTER_BY_DATE, result: { date, startDate, endDate } });
  };
};

export const filterByWarehouse = (outlet) => {
  return (dispatch) => {
    dispatch({ type: FILTER_BY_WAREHOUSE, result: outlet });
  };
};
