import React from 'react';
import { useHistory } from 'react-router-dom';
import { createPopper } from '@popperjs/core';
import { useDispatch } from 'react-redux';

import { removeAuthenticatedUser } from '../actions/authenticatedUser';

const profilePicture = require('../assets/img/profile-picture.png');

export default function ProfileDropdown() {
  const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
  const btnDropdownRef = React.createRef();
  const popoverDropdownRef = React.createRef();

  const history = useHistory();
  const dispatch = useDispatch();

  const openDropdownPopover = () => {
    createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
      placement: 'bottom-start',
    });
    setDropdownPopoverShow(true);
  };

  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
  };

  const onPressLogOut = (e) => {
    e.preventDefault();
    dispatch(removeAuthenticatedUser());
    history.replace('/');
  };

  return (
    <>
      {/* Profile Picture */}
      <a
        className="text-gray-600 block"
        href="#pablo"
        ref={btnDropdownRef}
        onClick={(e) => {
          e.preventDefault();
          dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
        }}>
        <div className="items-center flex">
          <span className="w-8 md:w-12 h-8 md:h-12 text-sm text-white bg-gray-300 inline-flex items-center justify-center rounded-full">
            <img
              alt=""
              className="w-full rounded-full align-middle border-none shadow-lg"
              src={profilePicture}
            />
          </span>
        </div>
      </a>

      {/* Dropdown Menu */}
      <div
        ref={popoverDropdownRef}
        className={`${
          dropdownPopoverShow ? 'block' : 'hidden'
        } bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48`}>
        <a
          href="#pablo"
          className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-gray-800"
          onClick={onPressLogOut}>
          Log Out
        </a>
        {/* <div className="h-0 my-2 border border-solid border-gray-200" /> */}
      </div>
    </>
  );
}
