import Swal from 'sweetalert2';
import { SUBMIT_SIGN_IN } from './_types';
import {
  API_URL,
  API_HEADER,
  API_RESPONSE_STATUS,
  API_RESPONSE_MESSAGE,
  API_RESPONSE,
  APP_NAME,
} from '../constants/env';
import { setAuthenticatedUser, removeAuthenticatedUser } from './authenticatedUser';

/**
 * @param data      phone, password
 */
export const submitSignIn = (data) => {
  return (dispatch) => {
    return fetch(`${API_URL}/login`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: API_HEADER,
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            dispatch(setAuthenticatedUser(response.data));
            break;

          case API_RESPONSE.FAILED:
          default:
            Swal.fire('Login Failed', response[API_RESPONSE_MESSAGE], 'error');
            return Promise.reject(response[API_RESPONSE_MESSAGE]);
        }
        dispatch({ type: SUBMIT_SIGN_IN, result: response });
      });
  };
};

export const submitSignOut = () => {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch(removeAuthenticatedUser());
      Swal.fire('LogOut Success', `LogOut dari ${APP_NAME} berhasil`, 'success');
      resolve();
    });
  };
};
