import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';

import Collapsible from '../../components/Collapsible';
import { InlineTextArea, InlineTextInput, SubmitButton } from '../../components/Base';
import { fetchSettings, submitSettings } from '../../actions/settings';

export default function Settings() {
  const { register, errors, handleSubmit } = useForm();

  const { result } = useSelector((state) => state.settings);
  const dispatch = useDispatch();

  const [isSubmittingForm, setIsSubmittingForm] = useState(false);

  useEffect(() => {
    dispatch(fetchSettings());
  }, []);

  const onSubmitForm = (data) => {
    setIsSubmittingForm(true);

    dispatch(submitSettings(data))
      .then(() => setIsSubmittingForm(false))
      .catch(() => setIsSubmittingForm(false));
  };

  return (
    <>
      <div className="relative flex flex-col min-w-0 w-full h-full bg-white mb-8 shadow-lg rounded-xl">
        {/* Header */}
        <div className="flex flex-wrap items-center py-3 border-b">
          <div className="px-4 flex-1">
            <h3 className="font-semibold text-gray-800 pl-2">Content Settings</h3>
          </div>
        </div>

        <div className="p-6">
          <form onSubmit={handleSubmit(onSubmitForm)}>
            <Collapsible title="Order">
              <InlineTextArea
                title="No Telp Notifikasi"
                inputRef={register({ required: true })}
                name="order_phone_notification"
                errorMessage={
                  errors.order_phone_notification && 'Kolom No Telp Notifikasi harus diisi'
                }
                rows={2}
                defaultValue={result.order_phone_notification}
              />
              <div className="-mt-3 mb-4 text-right text-xs">
                Notifikasi akan dikirimkan ke No Telp tersebut. Gunakan simbol koma (,) sebagai
                pemisah No Telp &emsp;
              </div>

              <InlineTextInput
                type="number"
                title="Refund PIN (6-digit)"
                inputRef={register({ required: true, minLength: 6, maxLength: 6 })}
                name="refund_pin"
                errorMessage={errors.refund_pin && 'Kolom Refund PIN harus diisi 6-digit PIN'}
                inputWidth="w-full md:w-1/3"
                defaultValue={result.refund_pin}
              />
            </Collapsible>

            <Collapsible title="Checkout &amp; Payment">
              <InlineTextArea
                title="Payment Remarks"
                inputRef={register()}
                name="payment_remarks"
                errorMessage={errors.payment_remarks && 'Kolom Payment Remarks harus diisi'}
                rows={4}
                defaultValue={result.payment_remarks}
              />
            </Collapsible>

            <Collapsible title="Footer">
              <InlineTextArea
                title="Footer Content"
                inputRef={register({ required: true })}
                name="footer"
                errorMessage={errors.footer && 'Kolom Footer Content harus diisi'}
                rows={4}
                defaultValue={result.footer}
              />
            </Collapsible>

            <SubmitButton
              type="submit"
              text="Save"
              textClass="text-white text-xs"
              isLoading={isSubmittingForm}
            />
          </form>
        </div>
      </div>
    </>
  );
}
