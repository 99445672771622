import React, { forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import { Dialog } from '@headlessui/react';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';

import {
  Modal,
  InlineTextInput,
  InlineSelect,
  InlineSwitch,
  InlineTextArea,
  BasicButton,
  SubmitButton,
} from '../../../components/Base';
import Collapsible from '../../../components/Collapsible';
import { APP_URL } from '../../../constants/env';
import { PRODUCT_CATEGORY_TYPES } from '../../../constants/data';
import Utils from '../../../helpers/utils';

import {
  fetchProductCategory,
  submitProductCategory,
  updateProductCategory,
} from '../../../actions/productCategory';

function ProductCategoryCreate(props, ref) {
  const { register, errors, handleSubmit, setValue, setError, clearErrors } = useForm();

  const { result: productCategories } = useSelector((state) => state.productCategory);
  const dispatch = useDispatch();

  const [isVisible, setIsVisible] = useState(false);
  const [initialProductCategory, setInitialProductCategory] = useState(null);
  const [image, setImage] = useState('');
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);

  //

  useEffect(() => {
    dispatch(fetchProductCategory());
  }, []);

  useEffect(() => {
    if (initialProductCategory) {
      setImage(`${APP_URL}/${initialProductCategory.image}`);
    }
  }, [initialProductCategory]);

  //

  useImperativeHandle(ref, () => ({
    showForm,
    showFormWithInitialData,
  }));

  const showForm = () => setIsVisible(true);
  const closeForm = () => {
    setIsVisible(false);
    setInitialProductCategory(null);
    setImage('');
    setIsSubmittingForm(false);
  };

  const showFormWithInitialData = (data) => {
    setInitialProductCategory(data);
    showForm();
  };

  //

  const generateUrlSlug = (e) => {
    const slug = Utils.stringToSlug(e.target.value);
    setValue('url_slug', slug);
  };

  const onImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const uploadedFile = e.target.files[0];

      if (uploadedFile.type.indexOf('image') === -1) {
        setError('image', {
          type: 'manual',
          message: 'Image must be an image (jpg, png, gif, svg, or webp)',
        });
        return;
      }

      if (uploadedFile.size > 1000000) {
        setError('image', {
          type: 'manual',
          message: "Image size can't be larger than 1 MB",
        });
        return;
      }

      clearErrors('image');
      setImage(URL.createObjectURL(e.target.files[0]));
    }
  };

  const onSubmitForm = (data) => {
    setIsSubmittingForm(true);

    const formData = new FormData();
    formData.append('name', data.name);
    formData.append('type', data.type);
    formData.append('parent_product_category_id', data.parent_product_category_id);
    formData.append('status', data.status ? 1 : 0);
    formData.append('is_featured', data.is_featured ? 1 : 0);
    formData.append('order', data.order);

    if (data.image.length > 0) formData.append('image', data.image[0]);
    formData.append('description', data.description);

    formData.append('url_slug', data.url_slug);
    formData.append('meta_title', data.meta_title);
    formData.append('meta_description', data.meta_description);
    formData.append('meta_keywords', data.meta_keywords);

    if (initialProductCategory) {
      dispatch(updateProductCategory(initialProductCategory.id, formData))
        .then(() => closeForm())
        .catch(() => setIsSubmittingForm(false));
    } else {
      dispatch(submitProductCategory(formData))
        .then(() => closeForm())
        .catch(() => setIsSubmittingForm(false));
    }
  };

  return (
    <Modal
      isVisible={isVisible}
      size="large"
      ModalContent={
        <div className="sm:flex sm:items-start">
          <div className="w-full mt-3 text-center sm:mt-0 sm:text-left">
            <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
              {initialProductCategory ? 'Ubah Kategori Produk' : 'Tambah Kategori Produk Baru'}
            </Dialog.Title>
            <div className="mt-5">
              <form id="createForm" onSubmit={handleSubmit(onSubmitForm)}>
                <Collapsible title="General">
                  <InlineTextInput
                    type="text"
                    title="Nama Kategori*"
                    inputRef={register({ required: true })}
                    name="name"
                    defaultValue={initialProductCategory?.name}
                    errorMessage={errors.name && 'Kolom Nama Kategori harus diisi'}
                    maxLength="200"
                    onBlur={generateUrlSlug}
                  />

                  <InlineSelect
                    title="Type*"
                    inputRef={register({ required: true })}
                    name="type"
                    options={Object.values(PRODUCT_CATEGORY_TYPES).map((t) => (
                      <option key={t} value={t}>
                        {t}
                      </option>
                    ))}
                    defaultValue={
                      initialProductCategory
                        ? initialProductCategory.type.replace(/&amp;/g, '&')
                        : PRODUCT_CATEGORY_TYPES.PHONE
                    }
                    errorMessage={errors.type && 'Kolom Type harus dipilih'}
                  />

                  <InlineSelect
                    title="Parent Kategori Produk"
                    inputRef={register()}
                    name="parent_product_category_id"
                    options={productCategories
                      .filter((c) => c.parent_product_category_id === null)
                      .map((c) => (
                        <option key={c.id} value={c.id}>
                          {c.name}
                        </option>
                      ))}
                    defaultValue={initialProductCategory?.parent_product_category_id}
                    errorMessage={
                      errors.parent_product_category_id && 'Kolom Kategori Produk harus dipilih'
                    }
                  />

                  <InlineSwitch
                    title="Status"
                    inputRef={register()}
                    name="status"
                    defaultChecked={
                      initialProductCategory ? initialProductCategory.status === 1 : true
                    }
                  />

                  <InlineSwitch
                    title="Featured"
                    inputRef={register()}
                    name="is_featured"
                    defaultChecked={
                      initialProductCategory && initialProductCategory.is_featured === 1
                    }
                  />

                  <InlineTextInput
                    inputWidth="w-1/2"
                    type="number"
                    title="Order"
                    inputRef={register()}
                    name="order"
                    defaultValue={initialProductCategory?.order}
                    errorMessage={errors.order && 'Kolom Order harus diisi'}
                  />
                </Collapsible>

                <Collapsible title="Description and Image">
                  <img src={image} alt="" className="w-32 mb-2" />
                  <InlineTextInput
                    type="file"
                    accept="image/*"
                    title="Image"
                    inputRef={register(/* { required: initialProductCategory ? false : true }*/)}
                    name="image"
                    onChange={onImageChange}
                    errorMessage={
                      errors.image &&
                      (errors.image.type === 'manual'
                        ? errors.image.message
                        : 'Kolom Image harus diisi')
                    }
                  />
                  <div className="-mt-3 mb-4 text-right text-xs">Image size max 1 MB &emsp;</div>

                  <InlineTextArea
                    title="Deskripsi*"
                    inputRef={register()}
                    name="description"
                    defaultValue={initialProductCategory?.description}
                    errorMessage={errors.description && 'Kolom Deskripsi harus diisi'}
                  />
                </Collapsible>

                <Collapsible title="SEO">
                  <InlineTextInput
                    type="text"
                    title="URL Slug"
                    inputRef={register({ required: true })}
                    name="url_slug"
                    defaultValue={initialProductCategory?.url_slug}
                    errorMessage={errors.url_slug && 'Kolom URL Slug harus diisi'}
                  />

                  <InlineTextInput
                    type="text"
                    title="Meta Title (max 50 chars)"
                    inputRef={register()}
                    name="meta_title"
                    defaultValue={initialProductCategory?.meta_title}
                    errorMessage={errors.meta_title && 'Kolom Meta Title harus diisi'}
                    maxLength="50"
                  />

                  <InlineTextArea
                    title="Meta Description (max 150 chars)"
                    inputRef={register()}
                    name="meta_description"
                    defaultValue={initialProductCategory?.meta_description}
                    errorMessage={errors.meta_description && 'Kolom Meta Description harus diisi'}
                    maxLength="150"
                  />

                  <InlineTextInput
                    type="text"
                    title="Meta Keywords"
                    inputRef={register()}
                    name="meta_keywords"
                    defaultValue={initialProductCategory?.meta_keywords}
                    errorMessage={errors.meta_keywords && 'Kolom Meta Keywords harus diisi'}
                    maxLength="250"
                  />
                </Collapsible>
              </form>
            </div>
          </div>
        </div>
      }
      ModalButton={
        <>
          <SubmitButton
            type="submit"
            form="createForm"
            text="Submit"
            textClass="text-white text-xs"
            isLoading={isSubmittingForm}
          />
          <BasicButton text="Cancel" textClass="text-white text-xs mr-2" onClick={closeForm} />
        </>
      }
    />
  );
}

export default forwardRef(ProductCategoryCreate);
