import Swal from 'sweetalert2';
import {
  FETCH_PRODUCT,
  FETCH_FEATURED_PRODUCT,
  FETCH_PAGINATED_PRODUCT,
  FETCH_PRODUCT_DETAIL,
  SUBMIT_PRODUCT,
  DELETE_PRODUCT,
  REFRESH_PRODUCT_DATATABLES,
} from './_types';
import { API_URL, API_RESPONSE_STATUS, API_RESPONSE_MESSAGE, API_RESPONSE } from '../constants/env';
import { authApiHeader, authApiHeaderMultipart } from '../helpers/apiHeader';

export const fetchProduct = () => {
  return (dispatch) => {
    return fetch(`${API_URL}/product`, {
      method: 'GET',
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            dispatch({ type: FETCH_PRODUCT, result: response.data });
            break;

          case API_RESPONSE.FAILED:
          default:
            dispatch({ type: FETCH_PRODUCT, result: [] });
            Swal.fire('Fetch Product Failed', response[API_RESPONSE_MESSAGE], 'error');
        }
      });
  };
};

export const fetchFeaturedProduct = () => {
  return (dispatch) => {
    return fetch(`${API_URL}/product?active=true&featured=true`, {
      method: 'GET',
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            dispatch({ type: FETCH_FEATURED_PRODUCT, result: response.data });
            break;

          case API_RESPONSE.FAILED:
          default:
            dispatch({ type: FETCH_FEATURED_PRODUCT, result: [] });
            Swal.fire('Fetch Product Failed', response[API_RESPONSE_MESSAGE], 'error');
        }
      });
  };
};

export const fetchPaginatedProduct = (search, category, sort, page) => {
  return (dispatch) => {
    let queryString = '';
    if (search && search !== '') queryString += `&s=${search}`;
    if (category) queryString += `&category=${category}`;
    if (sort) queryString += `&sort=${sort}`;
    if (page) queryString += `&page=${page}`;

    return fetch(`${API_URL}/product?source=pagination&active=true${queryString}`, {
      method: 'GET',
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            dispatch({ type: FETCH_PAGINATED_PRODUCT, result: response.data });
            break;

          case API_RESPONSE.FAILED:
          default:
            dispatch({ type: FETCH_PAGINATED_PRODUCT, result: [] });
            Swal.fire('Fetch Product Failed', response[API_RESPONSE_MESSAGE], 'error');
        }
      });
  };
};

export const refreshProductDatatables = () => {
  return (dispatch) => {
    dispatch({ type: REFRESH_PRODUCT_DATATABLES });
  };
};

export const setProductDetail = (product) => {
  return (dispatch) => {
    return dispatch({ type: FETCH_PRODUCT_DETAIL, result: { ...product } });
  };
};

export const fetchProductDetail = (slug) => {
  return (dispatch) => {
    return fetch(`${API_URL}/product/${slug}`, {
      method: 'GET',
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            dispatch({ type: FETCH_PRODUCT_DETAIL, result: { ...response.data } });
            break;

          case API_RESPONSE.FAILED:
          default:
            dispatch({ type: FETCH_PRODUCT_DETAIL, result: {} });
            Swal.fire('Fetch Product Failed', response[API_RESPONSE_MESSAGE], 'error');
        }
      });
  };
};

/**
 * @param data      FormData: product_category_id, name, url_slug, sku, is_new, is_featured, status,
 *                    description, meta_title, meta_description, meta_keywords,
 *                    original_price, discount_type, discount_amount, price,
 *                    width, height, depth, weight, image_1, image_2, image_3, image_4, image_5, related_product_ids,
 *                    product_variants[{ id, is_deleted, sku, name }]
 */
export const submitProduct = (data) => {
  return (dispatch) => {
    return fetch(`${API_URL}/product`, {
      method: 'POST',
      body: data,
      headers: authApiHeaderMultipart(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            Swal.fire('Submit Success', response[API_RESPONSE_MESSAGE], 'success');
            dispatch({ type: SUBMIT_PRODUCT, result: response });
            break;

          case API_RESPONSE.FAILED:
          default:
            Swal.fire('Submit Failed', response[API_RESPONSE_MESSAGE], 'error');
            return Promise.reject(response[API_RESPONSE_MESSAGE]);
        }
      })
      .then(() => dispatch(refreshProductDatatables()))
      .then(() => dispatch(fetchProduct()));
  };
};

/**
 * @param id
 * @param data      FormData: product_category_id, name, url_slug, sku, is_new, is_featured, status,
 *                    description, meta_title, meta_description, meta_keywords,
 *                    original_price, discount_type, discount_amount, price,
 *                    width, height, depth, weight, image_1, image_2, image_3, image_4, image_5, related_product_ids,
 *                    product_variants[{ id, is_deleted, sku, name }]
 */
export const updateProduct = (id, data) => {
  return (dispatch) => {
    data.append('_method', 'PUT');

    return fetch(`${API_URL}/product/${id}`, {
      method: 'POST',
      body: data,
      headers: authApiHeaderMultipart(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            Swal.fire('Submit Success', response[API_RESPONSE_MESSAGE], 'success');
            dispatch({ type: SUBMIT_PRODUCT, result: response });
            break;

          case API_RESPONSE.FAILED:
          default:
            Swal.fire('Submit Failed', response[API_RESPONSE_MESSAGE], 'error');
            return Promise.reject(response[API_RESPONSE_MESSAGE]);
        }
      })
      .then(() => dispatch(refreshProductDatatables()))
      .then(() => dispatch(fetchProduct()));
  };
};

/**
 * @param id
 */
export const deleteProduct = (id) => {
  return (dispatch) => {
    return fetch(`${API_URL}/product/${id}`, {
      method: 'DELETE',
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            Swal.fire('Delete Success', response[API_RESPONSE_MESSAGE], 'success');
            dispatch({ type: DELETE_PRODUCT, result: response });
            break;

          case API_RESPONSE.FAILED:
          default:
            Swal.fire('Delete Failed', response[API_RESPONSE_MESSAGE], 'error');
            return Promise.reject(response[API_RESPONSE_MESSAGE]);
        }
      })
      .then(() => dispatch(refreshProductDatatables()))
      .then(() => dispatch(fetchProduct()));
  };
};

/**
 * @param data      product_stocks[{ id, stock }]
 */
export const updateProductStock = (productId, data) => {
  return (dispatch) => {
    return fetch(`${API_URL}/product/${productId}/update-stock`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            Swal.fire('Submit Success', response[API_RESPONSE_MESSAGE], 'success');
            dispatch({ type: SUBMIT_PRODUCT, result: response });
            break;

          case API_RESPONSE.FAILED:
          default:
            Swal.fire('Submit Failed', response[API_RESPONSE_MESSAGE], 'error');
            return Promise.reject(response[API_RESPONSE_MESSAGE]);
        }
      })
      .then(() => dispatch(refreshProductDatatables()))
      .then(() => dispatch(fetchProduct()));
  };
};
