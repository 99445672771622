import Swal from 'sweetalert2';
import { FETCH_SETTINGS, SUBMIT_SETTINGS } from './_types';
import { API_URL, API_RESPONSE_STATUS, API_RESPONSE_MESSAGE, API_RESPONSE } from '../constants/env';
import { authApiHeader } from '../helpers/apiHeader';

export const fetchSettings = () => {
  return (dispatch) => {
    return fetch(`${API_URL}/settings`, {
      method: 'GET',
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            dispatch({ type: FETCH_SETTINGS, result: response.data });
            break;

          case API_RESPONSE.FAILED:
          default:
            dispatch({ type: FETCH_SETTINGS, result: {} });
            Swal.fire('Fetch Settings Failed', response[API_RESPONSE_MESSAGE], 'error');
        }
      });
  };
};

/**
 * @param data      payment_remarks, footer
 */
export const submitSettings = (data) => {
  return (dispatch) => {
    return fetch(`${API_URL}/settings`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            Swal.fire('Submit Success', response[API_RESPONSE_MESSAGE], 'success');
            dispatch({ type: SUBMIT_SETTINGS, result: response });
            break;

          case API_RESPONSE.FAILED:
          default:
            Swal.fire('Submit Failed', response[API_RESPONSE_MESSAGE], 'error');
            return Promise.reject(response[API_RESPONSE_MESSAGE]);
        }
      })
      .then(() => dispatch(fetchSettings()));
  };
};
