import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

export default function PrivateRoute(props) {
  const { result: authenticatedUser } = useSelector((state) => state.authenticatedUser);
  return authenticatedUser && authenticatedUser.is_admin === 1 ? (
    <Route {...props} />
  ) : (
    <Redirect from="*" to="/login" />
  );
}
