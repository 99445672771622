import {
  FETCH_SHIPPING_METHOD,
  SUBMIT_SHIPPING_METHOD,
  DELETE_SHIPPING_METHOD,
  REFRESH_SHIPPING_METHOD_DATATABLES,
  RESET_STORE,
} from '../actions/_types';

const initialState = {
  result: [],
  submitResult: {},
  deleteResult: {},

  refresh: false,
};

export default function shippingMethod(state = initialState, action) {
  switch (action.type) {
    case FETCH_SHIPPING_METHOD:
      return {
        ...state,
        result: action.result,
      };

    case SUBMIT_SHIPPING_METHOD:
      return {
        ...state,
        submitResult: action.result,
      };

    case DELETE_SHIPPING_METHOD:
      return {
        ...state,
        deleteResult: action.result,
      };

    case REFRESH_SHIPPING_METHOD_DATATABLES:
      return {
        ...state,
        refresh: !state.refresh,
      };

    case RESET_STORE:
      return { ...initialState };

    default:
      return state;
  }
}
