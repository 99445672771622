import Swal from 'sweetalert2';
import {
  FETCH_SHIPPING_METHOD,
  SUBMIT_SHIPPING_METHOD,
  DELETE_SHIPPING_METHOD,
  REFRESH_SHIPPING_METHOD_DATATABLES,
} from './_types';
import { API_URL, API_RESPONSE_STATUS, API_RESPONSE_MESSAGE, API_RESPONSE } from '../constants/env';
import { authApiHeader } from '../helpers/apiHeader';

export const fetchShippingMethod = () => {
  return (dispatch) => {
    return fetch(`${API_URL}/shipping-method`, {
      method: 'GET',
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            dispatch({ type: FETCH_SHIPPING_METHOD, result: response.data });
            break;

          case API_RESPONSE.FAILED:
          default:
            dispatch({ type: FETCH_SHIPPING_METHOD, result: [] });
            Swal.fire('Fetch Shipping Method Failed', response[API_RESPONSE_MESSAGE], 'error');
        }
      });
  };
};

export const refreshShippingMethodDatatables = () => {
  return (dispatch) => {
    dispatch({ type: REFRESH_SHIPPING_METHOD_DATATABLES });
  };
};

/**
 * @param data
 * @param data      name, additional_fee, status
 */
export const submitShippingMethod = (data) => {
  return (dispatch) => {
    return fetch(`${API_URL}/shipping-method`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            Swal.fire('Submit Success', response[API_RESPONSE_MESSAGE], 'success');
            dispatch({ type: SUBMIT_SHIPPING_METHOD, result: response });
            break;

          case API_RESPONSE.FAILED:
          default:
            Swal.fire('Submit Failed', response[API_RESPONSE_MESSAGE], 'error');
            return Promise.reject(response[API_RESPONSE_MESSAGE]);
        }
      })
      .then(() => dispatch(refreshShippingMethodDatatables()))
      .then(() => dispatch(fetchShippingMethod()));
  };
};

/**
 * @param id
 * @param data      name, additional_fee, status
 */
export const updateShippingMethod = (id, data) => {
  return (dispatch) => {
    return fetch(`${API_URL}/shipping-method/${id}`, {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            Swal.fire('Submit Success', response[API_RESPONSE_MESSAGE], 'success');
            dispatch({ type: SUBMIT_SHIPPING_METHOD, result: response });
            break;

          case API_RESPONSE.FAILED:
          default:
            Swal.fire('Submit Failed', response[API_RESPONSE_MESSAGE], 'error');
            return Promise.reject(response[API_RESPONSE_MESSAGE]);
        }
      })
      .then(() => dispatch(refreshShippingMethodDatatables()))
      .then(() => dispatch(fetchShippingMethod()));
  };
};

/**
 * @param id
 */
export const deleteShippingMethod = (id) => {
  return (dispatch) => {
    return fetch(`${API_URL}/shipping-method/${id}`, {
      method: 'DELETE',
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            Swal.fire('Delete Success', response[API_RESPONSE_MESSAGE], 'success');
            dispatch({ type: DELETE_SHIPPING_METHOD, result: response });
            break;

          case API_RESPONSE.FAILED:
          default:
            Swal.fire('Delete Failed', response[API_RESPONSE_MESSAGE], 'error');
            return Promise.reject(response[API_RESPONSE_MESSAGE]);
        }
      })
      .then(() => dispatch(refreshShippingMethodDatatables()))
      .then(() => dispatch(fetchShippingMethod()));
  };
};
