import React, { forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import { Dialog } from '@headlessui/react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import {
  Modal,
  InlineTextInput,
  InlineTextArea,
  InlineSelect,
  InlineSwitch,
  BasicButton,
  SubmitButton,
} from '../../../components/Base';
import Collapsible from '../../../components/Collapsible';

import { submitPaymentMethod, updatePaymentMethod } from '../../../actions/paymentMethod';

function PaymentMethodCreate(props, ref) {
  const { register, errors, handleSubmit, setValue } = useForm();

  const [isVisible, setIsVisible] = useState(false);
  const [initialPaymentMethod, setInitialPaymentMethod] = useState(null);
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);

  const dispatch = useDispatch();

  useImperativeHandle(ref, () => ({
    showForm,
    showFormWithInitialData,
  }));

  useEffect(() => {
    if (initialPaymentMethod) {
      setTimeout(() => {
        setValue('name', initialPaymentMethod.name);
        setValue('description', initialPaymentMethod.description);
        setValue('fee_type', initialPaymentMethod.fee_type);
        setValue('fee_amount', initialPaymentMethod.fee_amount);
        setValue('status', initialPaymentMethod.status === 1);
      }, 100);
    }
  }, [initialPaymentMethod]);

  const showForm = () => setIsVisible(true);
  const closeForm = () => {
    setIsVisible(false);
    setInitialPaymentMethod(null);
    setIsSubmittingForm(false);
  };

  const showFormWithInitialData = (data) => {
    setInitialPaymentMethod(data);
    showForm();
  };

  const onSubmitForm = (data) => {
    setIsSubmittingForm(true);

    data.status = data.status ? 1 : 0;

    if (initialPaymentMethod) {
      dispatch(updatePaymentMethod(initialPaymentMethod.id, data))
        .then(() => closeForm())
        .catch(() => setIsSubmittingForm(false));
    } else {
      dispatch(submitPaymentMethod(data))
        .then(() => closeForm())
        .catch(() => setIsSubmittingForm(false));
    }
  };

  return (
    <Modal
      isVisible={isVisible}
      size="large"
      ModalContent={
        <div className="sm:flex sm:items-start">
          <div className="w-full mt-3 text-center sm:mt-0 sm:text-left">
            <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
              {initialPaymentMethod ? 'Ubah Payment Method' : 'Tambah Payment Method Baru'}
            </Dialog.Title>
            <div className="mt-5">
              <form id="createForm" onSubmit={handleSubmit(onSubmitForm)}>
                <InlineTextInput
                  type="text"
                  title="Nama"
                  // inputRef={register({ required: true })}
                  // name="name"
                  // errorMessage={errors.name && 'Kolom Nama harus diisi'}
                  value={initialPaymentMethod ? initialPaymentMethod.name : ''}
                  disabled
                  readOnly
                />

                <InlineTextArea
                  title="Deskripsi"
                  inputRef={register({ required: true })}
                  name="description"
                  errorMessage={errors.description && 'Kolom Deskripsi harus diisi'}
                />

                <InlineSelect
                  title="Tipe Biaya"
                  inputRef={register({ required: true })}
                  name="fee_type"
                  options={[
                    <option key={0} value="Fixed Amount">
                      Jumlah Tetap (Rp)
                    </option>,
                    <option key={1} value="Percentage">
                      Persentase (%)
                    </option>,
                  ]}
                  errorMessage={errors.fee_type && 'Kolom Tipe Biaya harus dipilih'}
                />

                <InlineTextInput
                  type="number"
                  title="Jumlah Biaya"
                  inputRef={register({ required: true })}
                  name="fee_amount"
                  step="0.01"
                  errorMessage={errors.fee_amount && 'Kolom Jumlah Biaya harus diisi'}
                />

                <InlineSwitch
                  title="Status"
                  inputRef={register()}
                  name="status"
                  defaultChecked={initialPaymentMethod ? initialPaymentMethod.status === 1 : true}
                />

                {/* PAYMENT GATEWAY CODE FORM */}
                {initialPaymentMethod && Number(initialPaymentMethod.payment_gateway) === 1 && (
                  <Collapsible title="iPay88 Payment Code">
                    {initialPaymentMethod.payment_gateway_codes.map((paymentGatewayCode, index) => (
                      <>
                        <input
                          type="hidden"
                          ref={register()}
                          name={`payment_gateway_codes[${index}].id`}
                          defaultValue={paymentGatewayCode.id}
                        />
                        <InlineTextInput
                          type="text"
                          title={paymentGatewayCode.warehouse?.name}
                          inputRef={register()}
                          name={`payment_gateway_codes[${index}].code`}
                          defaultValue={paymentGatewayCode.code}
                        />
                      </>
                    ))}
                  </Collapsible>
                )}
              </form>
            </div>
          </div>
        </div>
      }
      ModalButton={
        <>
          <SubmitButton
            type="submit"
            form="createForm"
            text="Submit"
            textClass="text-white text-xs"
            isLoading={isSubmittingForm}
          />
          <BasicButton text="Cancel" textClass="text-white text-xs mr-2" onClick={closeForm} />
        </>
      }
    />
  );
}

export default forwardRef(PaymentMethodCreate);
