import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

// DataTables
import 'jszip';
import 'pdfmake';
import 'datatables.net-dt/js/dataTables.dataTables';
import 'datatables.net-buttons-dt';
import 'datatables.net-buttons/js/buttons.colVis.js';
import 'datatables.net-buttons/js/buttons.html5.js';
import 'datatables.net-buttons/js/buttons.print.js';
import 'datatables.net-fixedcolumns-dt';
import 'datatables.net-dt/css/jquery.dataTables.min.css';

import PrivateRoleRoute from './PrivateRoleRoute';

// Components
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import Footer from './Footer';

// Views
import Dashboard from './Dashboard';

import Order from './Order';
import OrderPending from './OrderPending';
import OrderRefund from './OrderRefund';

import PhoneService from './PhoneService';
import PhoneServiceHistory from './PhoneServiceHistory';
import Technician from './Technician';

import Product from './Product';
import ProductCategory from './ProductCategory';

import Review from './Review';

import Voucher from './Voucher';

import Page from './Page';

import Slider from './Slider';
import Admin from './Admin';
import Role from './Role';
import PaymentMethod from './PaymentMethod';
import ShippingMethod from './ShippingMethod';
import Warehouse from './Warehouse';
import Whatsapp from './Whatsapp';
import Settings from './Settings';

export default function Backoffice() {
  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64 bg-gray-100">
        <Navbar />
        <div className="w-full min-h-screen px-4 md:px-10 pt-10 md:pt-24 mx-auto">
          <div className="min-h-100">
            <Switch>
              <PrivateRoleRoute path="/dashboard" exact component={Dashboard} />

              <PrivateRoleRoute path="/order" exact component={Order} />
              <PrivateRoleRoute path="/pending-order" exact component={OrderPending} />
              <PrivateRoleRoute path="/refund" exact component={OrderRefund} />

              <PrivateRoleRoute path="/phone-service" exact component={PhoneService} />
              <PrivateRoleRoute
                path="/phone-service-history"
                exact
                component={PhoneServiceHistory}
              />
              <PrivateRoleRoute path="/technician" exact component={Technician} />

              <PrivateRoleRoute path="/product" exact component={Product} />
              <PrivateRoleRoute path="/product-category" exact component={ProductCategory} />

              <PrivateRoleRoute path="/review" exact component={Review} />

              <PrivateRoleRoute path="/voucher" exact component={Voucher} />

              <PrivateRoleRoute path="/page" exact component={Page} />

              <PrivateRoleRoute path="/slider" exact component={Slider} />
              <PrivateRoleRoute path="/admin" exact component={Admin} />
              <PrivateRoleRoute path="/role" exact component={Role} />
              <PrivateRoleRoute path="/payment-method" exact component={PaymentMethod} />
              <PrivateRoleRoute path="/shipping-method" exact component={ShippingMethod} />
              <PrivateRoleRoute path="/warehouse" exact component={Warehouse} />
              <PrivateRoleRoute path="/whatsapp" exact component={Whatsapp} />
              <PrivateRoleRoute path="/settings" exact component={Settings} />

              <Redirect from="/" to="/dashboard" />
            </Switch>
          </div>

          <div className="mt-10">
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
}
