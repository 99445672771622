import React from 'react';
import {
  ChartSquareBarIcon,
  ShoppingCartIcon,
  // TruckIcon,
  PaperClipIcon,
  ReceiptRefundIcon,
  // ChipIcon,
  // ClipboardListIcon,
  // BriefcaseIcon,
  CubeIcon,
  CollectionIcon,

  // UserCircleIcon,
  StarIcon,

  // TagIcon,
  TicketIcon,
  DocumentSearchIcon,
  DuplicateIcon,
  UsersIcon,
  IdentificationIcon,
  CreditCardIcon,
  PaperAirplaneIcon,
  InboxIcon,
  PhoneIcon,
  // OfficeBuildingIcon,
  CogIcon,
} from '@heroicons/react/outline';

const MENU_ICON_CLASS = 'mr-2 h-5 w-5 opacity-75';
export const MENUS = [
  {
    name: 'Dashboard',
    childs: [
      {
        id: '1-1',
        name: 'Dashboard',
        icon: <ChartSquareBarIcon className={MENU_ICON_CLASS} />,
        route: '/dashboard',
      },
    ],
  },
  {
    name: 'Sales',
    childs: [
      {
        id: '2-1',
        name: 'Order',
        icon: <ShoppingCartIcon className={MENU_ICON_CLASS} />,
        route: '/order',
      },
      /* {
        id: '2-2',
        name: 'Shipping',
        icon: <TruckIcon className={MENU_ICON_CLASS} />,
        route: '/shipment',
      }, */
      {
        id: '2-3',
        name: 'Pending Payment',
        icon: <PaperClipIcon className={MENU_ICON_CLASS} />,
        route: '/pending-order',
      },
      {
        id: '2-4',
        name: 'Refund',
        icon: <ReceiptRefundIcon className={MENU_ICON_CLASS} />,
        route: '/refund',
      },
    ],
  },
  /* {
    name: 'Service',
    childs: [
      {
        id: '3-1',
        name: 'Available Service',
        icon: <ChipIcon className={MENU_ICON_CLASS} />,
        route: '/phone-service',
      },
      {
        id: '3-2',
        name: 'Service History',
        icon: <ClipboardListIcon className={MENU_ICON_CLASS} />,
        route: '/phone-service-history',
      },
      {
        id: '3-3',
        name: 'Technician',
        icon: <BriefcaseIcon className={MENU_ICON_CLASS} />,
        route: '/technician',
      },
    ],
  }, */
  {
    name: 'Catalog',
    childs: [
      {
        id: '4-1',
        name: 'Product',
        icon: <CubeIcon className={MENU_ICON_CLASS} />,
        route: '/product',
      },
      {
        id: '4-2',
        name: 'Category',
        icon: <CollectionIcon className={MENU_ICON_CLASS} />,
        route: '/product-category',
      },
    ],
  },
  {
    name: 'Customer',
    childs: [
      /* {
        id: '5-1',
        name: 'Customer',
        icon: <UserCircleIcon className={MENU_ICON_CLASS} />,
        route: '/customer',
      }, */
      {
        id: '5-2',
        name: 'Review',
        icon: <StarIcon className={MENU_ICON_CLASS} />,
        route: '/review',
      },
    ],
  },
  {
    name: 'Marketing',
    childs: [
      {
        id: '6-1',
        name: 'Voucher',
        icon: <TicketIcon className={MENU_ICON_CLASS} />,
        route: '/voucher',
      },
    ],
  },
  {
    name: 'CMS',
    childs: [
      {
        id: '7-1',
        name: 'Pages',
        icon: <DocumentSearchIcon className={MENU_ICON_CLASS} />,
        route: '/page',
      },
    ],
  },
  {
    name: 'Settings',
    childs: [
      {
        id: '8-1',
        name: 'Slider',
        icon: <DuplicateIcon className={MENU_ICON_CLASS} />,
        route: '/slider',
      },
      {
        id: '8-2',
        name: 'Admin',
        icon: <UsersIcon className={MENU_ICON_CLASS} />,
        route: '/admin',
      },
      {
        id: '8-3',
        name: 'Role',
        icon: <IdentificationIcon className={MENU_ICON_CLASS} />,
        route: '/role',
      },
      {
        id: '8-4',
        name: 'Payment Method',
        icon: <CreditCardIcon className={MENU_ICON_CLASS} />,
        route: '/payment-method',
      },
      {
        id: '8-5',
        name: 'Shipping Method',
        icon: <PaperAirplaneIcon className={MENU_ICON_CLASS} />,
        route: '/shipping-method',
      },
      {
        id: '8-6',
        name: 'Store',
        icon: <InboxIcon className={MENU_ICON_CLASS} />,
        route: '/warehouse',
      },
      {
        id: '8-7',
        name: 'Whatsapp',
        icon: <PhoneIcon className={MENU_ICON_CLASS} />,
        route: '/whatsapp',
      },
      {
        id: '8-8',
        name: 'Content Settings',
        icon: <CogIcon className={MENU_ICON_CLASS} />,
        route: '/settings',
      },
    ],
  },
];
