import Swal from 'sweetalert2';
import { FETCH_ADMIN, SUBMIT_ADMIN, DELETE_ADMIN, REFRESH_ADMIN_DATATABLES } from './_types';
import { API_URL, API_RESPONSE_STATUS, API_RESPONSE_MESSAGE, API_RESPONSE } from '../constants/env';
import { authApiHeader } from '../helpers/apiHeader';

export const fetchAdmin = () => {
  return (dispatch) => {
    return fetch(`${API_URL}/admin`, {
      method: 'GET',
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            dispatch({ type: FETCH_ADMIN, result: response.data });
            break;

          case API_RESPONSE.FAILED:
          default:
            dispatch({ type: FETCH_ADMIN, result: [] });
            Swal.fire('Fetch Admin Failed', response[API_RESPONSE_MESSAGE], 'error');
        }
      });
  };
};

export const refreshAdminDatatables = () => {
  return (dispatch) => {
    dispatch({ type: REFRESH_ADMIN_DATATABLES });
  };
};

/**
 * @param data      phone, first_name, last_name, password, gender, role_id
 */
export const submitAdmin = (data) => {
  return (dispatch) => {
    return fetch(`${API_URL}/admin`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            Swal.fire('Submit Success', response[API_RESPONSE_MESSAGE], 'success');
            dispatch({ type: SUBMIT_ADMIN, result: response });
            break;

          case API_RESPONSE.FAILED:
          default:
            Swal.fire('Submit Failed', response[API_RESPONSE_MESSAGE], 'error');
            return Promise.reject(response[API_RESPONSE_MESSAGE]);
        }
      })
      .then(() => dispatch(refreshAdminDatatables()))
      .then(() => dispatch(fetchAdmin()));
  };
};

/**
 * @param id
 * @param data      first_name, last_name, password, gender, role_id
 */
export const updateAdmin = (id, data) => {
  return (dispatch) => {
    return fetch(`${API_URL}/admin/${id}`, {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            Swal.fire('Submit Success', response[API_RESPONSE_MESSAGE], 'success');
            dispatch({ type: SUBMIT_ADMIN, result: response });
            break;

          case API_RESPONSE.FAILED:
          default:
            Swal.fire('Submit Failed', response[API_RESPONSE_MESSAGE], 'error');
            return Promise.reject(response[API_RESPONSE_MESSAGE]);
        }
      })
      .then(() => dispatch(refreshAdminDatatables()))
      .then(() => dispatch(fetchAdmin()));
  };
};

/**
 * @param id
 */
export const deleteAdmin = (id) => {
  return (dispatch) => {
    return fetch(`${API_URL}/admin/${id}`, {
      method: 'DELETE',
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            Swal.fire('Delete Success', response[API_RESPONSE_MESSAGE], 'success');
            dispatch({ type: DELETE_ADMIN, result: response });
            break;

          case API_RESPONSE.FAILED:
          default:
            Swal.fire('Delete Failed', response[API_RESPONSE_MESSAGE], 'error');
            return Promise.reject(response[API_RESPONSE_MESSAGE]);
        }
      })
      .then(() => dispatch(refreshAdminDatatables()))
      .then(() => dispatch(fetchAdmin()));
  };
};
