import Swal from 'sweetalert2';
import { FETCH_SLIDER, SUBMIT_SLIDER, DELETE_SLIDER, REFRESH_SLIDER_DATATABLES } from './_types';
import { API_URL, API_RESPONSE_STATUS, API_RESPONSE_MESSAGE, API_RESPONSE } from '../constants/env';
import { authApiHeader, authApiHeaderMultipart } from '../helpers/apiHeader';

export const fetchSlider = () => {
  return (dispatch) => {
    return fetch(`${API_URL}/slider`, {
      method: 'GET',
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            dispatch({ type: FETCH_SLIDER, result: response.data });
            break;

          case API_RESPONSE.FAILED:
          default:
            dispatch({ type: FETCH_SLIDER, result: [] });
            Swal.fire('Fetch Slider Failed', response[API_RESPONSE_MESSAGE], 'error');
        }
      });
  };
};

export const refreshSliderDatatables = () => {
  return (dispatch) => {
    dispatch({ type: REFRESH_SLIDER_DATATABLES });
  };
};

/**
 * @param data      FormData: image, url
 */
export const submitSlider = (data) => {
  return (dispatch) => {
    return fetch(`${API_URL}/slider`, {
      method: 'POST',
      body: data,
      headers: authApiHeaderMultipart(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            Swal.fire('Submit Success', response[API_RESPONSE_MESSAGE], 'success');
            dispatch({ type: SUBMIT_SLIDER, result: response });
            break;

          case API_RESPONSE.FAILED:
          default:
            Swal.fire('Submit Failed', response[API_RESPONSE_MESSAGE], 'error');
            return Promise.reject(response[API_RESPONSE_MESSAGE]);
        }
      })
      .then(() => dispatch(refreshSliderDatatables()))
      .then(() => dispatch(fetchSlider()));
  };
};

/**
 * @param id
 * @param data      FormData: image, url
 */
export const updateSlider = (id, data) => {
  return (dispatch) => {
    data.append('_method', 'PUT');

    return fetch(`${API_URL}/slider/${id}`, {
      method: 'POST',
      body: data,
      headers: authApiHeaderMultipart(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            Swal.fire('Submit Success', response[API_RESPONSE_MESSAGE], 'success');
            dispatch({ type: SUBMIT_SLIDER, result: response });
            break;

          case API_RESPONSE.FAILED:
          default:
            Swal.fire('Submit Failed', response[API_RESPONSE_MESSAGE], 'error');
            return Promise.reject(response[API_RESPONSE_MESSAGE]);
        }
      })
      .then(() => dispatch(refreshSliderDatatables()))
      .then(() => dispatch(fetchSlider()));
  };
};

/**
 * @param id
 */
export const deleteSlider = (id) => {
  return (dispatch) => {
    return fetch(`${API_URL}/slider/${id}`, {
      method: 'DELETE',
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            Swal.fire('Delete Success', response[API_RESPONSE_MESSAGE], 'success');
            dispatch({ type: DELETE_SLIDER, result: response });
            break;

          case API_RESPONSE.FAILED:
          default:
            Swal.fire('Delete Failed', response[API_RESPONSE_MESSAGE], 'error');
            return Promise.reject(response[API_RESPONSE_MESSAGE]);
        }
      })
      .then(() => dispatch(refreshSliderDatatables()))
      .then(() => dispatch(fetchSlider()));
  };
};
