import Swal from 'sweetalert2';
import { FETCH_ROLE, SUBMIT_ROLE, DELETE_ROLE, REFRESH_ROLE_DATATABLES } from './_types';
import { API_URL, API_RESPONSE_STATUS, API_RESPONSE_MESSAGE, API_RESPONSE } from '../constants/env';
import { authApiHeader } from '../helpers/apiHeader';

export const fetchRole = () => {
  return (dispatch) => {
    return fetch(`${API_URL}/role`, {
      method: 'GET',
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            dispatch({ type: FETCH_ROLE, result: response.data });
            break;

          case API_RESPONSE.FAILED:
          default:
            dispatch({ type: FETCH_ROLE, result: [] });
            Swal.fire('Fetch Role Failed', response[API_RESPONSE_MESSAGE], 'error');
        }
      });
  };
};

export const refreshRoleDatatables = () => {
  return (dispatch) => {
    dispatch({ type: REFRESH_ROLE_DATATABLES });
  };
};

/**
 * @param data      name, permissions
 */
export const submitRole = (data) => {
  return (dispatch) => {
    return fetch(`${API_URL}/role`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            Swal.fire('Submit Success', response[API_RESPONSE_MESSAGE], 'success');
            dispatch({ type: SUBMIT_ROLE, result: response });
            break;

          case API_RESPONSE.FAILED:
          default:
            Swal.fire('Submit Failed', response[API_RESPONSE_MESSAGE], 'error');
            return Promise.reject(response[API_RESPONSE_MESSAGE]);
        }
      })
      .then(() => dispatch(refreshRoleDatatables()))
      .then(() => dispatch(fetchRole()));
  };
};

/**
 * @param id
 * @param data      name, permissions
 */
export const updateRole = (id, data) => {
  return (dispatch) => {
    return fetch(`${API_URL}/role/${id}`, {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            Swal.fire('Submit Success', response[API_RESPONSE_MESSAGE], 'success');
            dispatch({ type: SUBMIT_ROLE, result: response });
            break;

          case API_RESPONSE.FAILED:
          default:
            Swal.fire('Submit Failed', response[API_RESPONSE_MESSAGE], 'error');
            return Promise.reject(response[API_RESPONSE_MESSAGE]);
        }
      })
      .then(() => dispatch(refreshRoleDatatables()))
      .then(() => dispatch(fetchRole()));
  };
};

/**
 * @param id
 */
export const deleteRole = (id) => {
  return (dispatch) => {
    return fetch(`${API_URL}/role/${id}`, {
      method: 'DELETE',
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            Swal.fire('Delete Success', response[API_RESPONSE_MESSAGE], 'success');
            dispatch({ type: DELETE_ROLE, result: response });
            break;

          case API_RESPONSE.FAILED:
          default:
            Swal.fire('Delete Failed', response[API_RESPONSE_MESSAGE], 'error');
            return Promise.reject(response[API_RESPONSE_MESSAGE]);
        }
      })
      .then(() => dispatch(refreshRoleDatatables()))
      .then(() => dispatch(fetchRole()));
  };
};
