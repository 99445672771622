/* AUTHENTICATED DATA */
export const SET_AUTHENTICATED_USER = 'SET_AUTHENTICATED_USER';
export const EMPTY_AUTHENTICATED_USER = 'EMPTY_AUTHENTICATED_USER';

export const RESET_STORE = 'RESET_STORE';

export const SUBMIT_SIGN_IN = 'SUBMIT_SIGN_IN';
export const SUBMIT_SIGN_OUT = 'SUBMIT_SIGN_OUT';

/* PROFILE & PASSWORD */
export const SUBMIT_CHANGE_PROFILE = 'SUBMIT_CHANGE_PROFILE';
export const SUBMIT_CHANGE_PROFILE_PICTURE = 'SUBMIT_CHANGE_PROFILE_PICTURE';
export const SUBMIT_CHANGE_PASSWORD = 'SUBMIT_CHANGE_PASSWORD';

/* RAJA ONGKIR */
export const FETCH_PROVINCE = 'FETCH_PROVINCE';
export const FETCH_CITY = 'FETCH_CITY';
export const FETCH_DISTRICT = 'FETCH_DISTRICT';
export const FETCH_SHIPPING_FEE = 'FETCH_SHIPPING_FEE';

/* DASHBOARD */
export const FETCH_DASHBOARD_ORDER_SUMMARIES = 'FETCH_DASHBOARD_ORDER_SUMMARIES';
export const FETCH_DASHBOARD_ORDER_STATISTICS = 'FETCH_DASHBOARD_ORDER_STATISTICS';

export const FILTER_BY_DATE = 'FILTER_BY_DATE';
export const FILTER_BY_WAREHOUSE = 'FILTER_BY_WAREHOUSE';

/* ORDER */
export const FETCH_ORDER = 'FETCH_ORDER';
export const FETCH_ORDER_DETAIL = 'FETCH_ORDER_DETAIL';
export const SUBMIT_ORDER = 'SUBMIT_ORDER';
export const REFRESH_ORDER_DATATABLES = 'REFRESH_ORDER_DATATABLES';
export const SUBMIT_ORDER_PAYMENT_CONFIRMATION = 'SUBMIT_ORDER_PAYMENT_CONFIRMATION';
export const ACCEPT_REJECT_PAYMENT_CONFIRMATION = 'ACCEPT_REJECT_PAYMENT_CONFIRMATION';
export const REFUND_ORDER = 'REFUND_ORDER';

/* PHONE SERVICE */
export const FETCH_PHONE_SERVICE = 'FETCH_PHONE_SERVICE';
export const SUBMIT_PHONE_SERVICE = 'SUBMIT_PHONE_SERVICE';
export const DELETE_PHONE_SERVICE = 'DELETE_PHONE_SERVICE';
export const REFRESH_PHONE_SERVICE_DATATABLES = 'REFRESH_PHONE_SERVICE_DATATABLES';

/* TECHNICIAN */
export const FETCH_TECHNICIAN = 'FETCH_TECHNICIAN';
export const SUBMIT_TECHNICIAN = 'SUBMIT_TECHNICIAN';
export const DELETE_TECHNICIAN = 'DELETE_TECHNICIAN';
export const REFRESH_TECHNICIAN_DATATABLES = 'REFRESH_TECHNICIAN_DATATABLES';

/* PRODUCT & PRODUCT CATEGORY */
export const FETCH_PRODUCT = 'FETCH_PRODUCT';
export const FETCH_FEATURED_PRODUCT = 'FETCH_FEATURED_PRODUCT';
export const FETCH_PAGINATED_PRODUCT = 'FETCH_PAGINATED_PRODUCT';
export const FETCH_PRODUCT_DETAIL = 'FETCH_PRODUCT_DETAIL';
export const SUBMIT_PRODUCT = 'SUBMIT_PRODUCT';
export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const REFRESH_PRODUCT_DATATABLES = 'REFRESH_PRODUCT_DATATABLES';

export const FETCH_PRODUCT_CATEGORY = 'FETCH_PRODUCT_CATEGORY';
export const FETCH_FEATURED_PRODUCT_CATEGORY = 'FETCH_FEATURED_PRODUCT_CATEGORY';
export const SUBMIT_PRODUCT_CATEGORY = 'SUBMIT_PRODUCT_CATEGORY';
export const DELETE_PRODUCT_CATEGORY = 'DELETE_PRODUCT_CATEGORY';
export const REFRESH_PRODUCT_CATEGORY_DATATABLES = 'REFRESH_PRODUCT_CATEGORY_DATATABLES';

/* REVIEW */
export const FETCH_REVIEW = 'FETCH_REVIEW';
export const SUBMIT_REVIEW = 'SUBMIT_REVIEW';
export const DELETE_REVIEW = 'DELETE_REVIEW';
export const REFRESH_REVIEW_DATATABLES = 'REFRESH_REVIEW_DATATABLES';

/* VOUCHER */
export const FETCH_VOUCHER = 'FETCH_VOUCHER';
export const SUBMIT_VOUCHER = 'SUBMIT_VOUCHER';
export const DELETE_VOUCHER = 'DELETE_VOUCHER';
export const REFRESH_VOUCHER_DATATABLES = 'REFRESH_VOUCHER_DATATABLES';

/* PAGE */
export const FETCH_PAGE = 'FETCH_PAGE';
export const SUBMIT_PAGE = 'SUBMIT_PAGE';
export const DELETE_PAGE = 'DELETE_PAGE';
export const REFRESH_PAGE_DATATABLES = 'REFRESH_PAGE_DATATABLES';

/* SLIDER */
export const FETCH_SLIDER = 'FETCH_SLIDER';
export const SUBMIT_SLIDER = 'SUBMIT_SLIDER';
export const DELETE_SLIDER = 'DELETE_SLIDER';
export const REFRESH_SLIDER_DATATABLES = 'REFRESH_SLIDER_DATATABLES';

/* ADMIN */
export const FETCH_ADMIN = 'FETCH_ADMIN';
export const SUBMIT_ADMIN = 'SUBMIT_ADMIN';
export const DELETE_ADMIN = 'DELETE_ADMIN';
export const REFRESH_ADMIN_DATATABLES = 'REFRESH_ADMIN_DATATABLES';

/* ROLE */
export const FETCH_ROLE = 'FETCH_ROLE';
export const SUBMIT_ROLE = 'SUBMIT_ROLE';
export const DELETE_ROLE = 'DELETE_ROLE';
export const REFRESH_ROLE_DATATABLES = 'REFRESH_ROLE_DATATABLES';

/* PAYMENT METHOD */
export const FETCH_PAYMENT_METHOD = 'FETCH_PAYMENT_METHOD';
export const SUBMIT_PAYMENT_METHOD = 'SUBMIT_PAYMENT_METHOD';
export const DELETE_PAYMENT_METHOD = 'DELETE_PAYMENT_METHOD';
export const REFRESH_PAYMENT_METHOD_DATATABLES = 'REFRESH_PAYMENT_METHOD_DATATABLES';

/* SHIPPING METHOD */
export const FETCH_SHIPPING_METHOD = 'FETCH_SHIPPING_METHOD';
export const SUBMIT_SHIPPING_METHOD = 'SUBMIT_SHIPPING_METHOD';
export const DELETE_SHIPPING_METHOD = 'DELETE_SHIPPING_METHOD';
export const REFRESH_SHIPPING_METHOD_DATATABLES = 'REFRESH_SHIPPING_METHOD_DATATABLES';

/* WAREHOUSE */
export const FETCH_WAREHOUSE = 'FETCH_WAREHOUSE';
export const SUBMIT_WAREHOUSE = 'SUBMIT_WAREHOUSE';
export const DELETE_WAREHOUSE = 'DELETE_WAREHOUSE';
export const REFRESH_WAREHOUSE_DATATABLES = 'REFRESH_WAREHOUSE_DATATABLES';

/* WHATSAPP */
export const FETCH_WHATSAPP_TEMPLATE = 'FETCH_WHATSAPP_TEMPLATE';
export const SUBMIT_WHATSAPP_TEMPLATE = 'SUBMIT_WHATSAPP_TEMPLATE';
export const FETCH_WHATSAPP_QR = 'FETCH_WHATSAPP_QR';

/* SETTINGS */
export const FETCH_SETTINGS = 'FETCH_SETTINGS';
export const SUBMIT_SETTINGS = 'SUBMIT_SETTINGS';
