import React from 'react';
import moment from 'moment';

export default function Footer() {
  return (
    <>
      <footer className="block py-4 mt-20">
        <div className="container mx-auto px-4">
          <hr className="mb-4 border-b-1 border-gray-300" />
          <div className="flex flex-wrap items-center md:justify-between justify-center">
            <div className="w-full px-4">
              <div className="text-sm text-gray-500 font-semibold py-1 text-center md:text-right">
                <div>Copyright &copy; {moment().format('Y')}. BSTORE. All Rights Reserved.</div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
