import Swal from 'sweetalert2';
import {
  FETCH_PHONE_SERVICE,
  SUBMIT_PHONE_SERVICE,
  DELETE_PHONE_SERVICE,
  REFRESH_PHONE_SERVICE_DATATABLES,
} from './_types';
import { API_URL, API_RESPONSE_STATUS, API_RESPONSE_MESSAGE, API_RESPONSE } from '../constants/env';
import { authApiHeader } from '../helpers/apiHeader';

export const fetchPhoneService = () => {
  return (dispatch) => {
    return fetch(`${API_URL}/phone-service`, {
      method: 'GET',
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            dispatch({ type: FETCH_PHONE_SERVICE, result: response.data });
            break;

          case API_RESPONSE.FAILED:
          default:
            dispatch({ type: FETCH_PHONE_SERVICE, result: [] });
            Swal.fire('Fetch Service Failed', response[API_RESPONSE_MESSAGE], 'error');
        }
      });
  };
};

export const refreshPhoneServiceDatatables = () => {
  return (dispatch) => {
    dispatch({ type: REFRESH_PHONE_SERVICE_DATATABLES });
  };
};

/**
 * @param data      pic, phone_brand, phone_model, description, customer_name, customer_phone
 */
export const submitPhoneService = (data) => {
  return (dispatch) => {
    return fetch(`${API_URL}/phone-service`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            Swal.fire('Submit Success', response[API_RESPONSE_MESSAGE], 'success');
            dispatch({ type: SUBMIT_PHONE_SERVICE, result: response });
            break;

          case API_RESPONSE.FAILED:
          default:
            Swal.fire('Submit Failed', response[API_RESPONSE_MESSAGE], 'error');
            return Promise.reject(response[API_RESPONSE_MESSAGE]);
        }
      })
      .then(() => dispatch(refreshPhoneServiceDatatables()))
      .then(() => dispatch(fetchPhoneService()));
  };
};

/**
 * @param id
 * @param data      technician_id, technician_nik, technician_name, pic, phone_brand, phone_model,
 *                  description, customer_name, customer_phone
 */
export const updatePhoneService = (id, data) => {
  return (dispatch) => {
    return fetch(`${API_URL}/phone-service/${id}`, {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            Swal.fire('Submit Success', response[API_RESPONSE_MESSAGE], 'success');
            dispatch({ type: SUBMIT_PHONE_SERVICE, result: response });
            break;

          case API_RESPONSE.FAILED:
          default:
            Swal.fire('Submit Failed', response[API_RESPONSE_MESSAGE], 'error');
            return Promise.reject(response[API_RESPONSE_MESSAGE]);
        }
      })
      .then(() => dispatch(refreshPhoneServiceDatatables()))
      .then(() => dispatch(fetchPhoneService()));
  };
};

/**
 * @param id
 */
export const deletePhoneService = (id) => {
  return (dispatch) => {
    return fetch(`${API_URL}/phone-service/${id}`, {
      method: 'DELETE',
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            Swal.fire('Delete Success', response[API_RESPONSE_MESSAGE], 'success');
            dispatch({ type: DELETE_PHONE_SERVICE, result: response });
            break;

          case API_RESPONSE.FAILED:
          default:
            Swal.fire('Delete Failed', response[API_RESPONSE_MESSAGE], 'error');
            return Promise.reject(response[API_RESPONSE_MESSAGE]);
        }
      })
      .then(() => dispatch(refreshPhoneServiceDatatables()))
      .then(() => dispatch(fetchPhoneService()));
  };
};
