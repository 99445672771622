import React, { forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import { Dialog } from '@headlessui/react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { Modal, InlineTextInput, BasicButton, SubmitButton } from '../../../components/Base';
import { APP_URL } from '../../../constants/env';
import { submitSlider, updateSlider } from '../../../actions/slider';

function SliderCreate(props, ref) {
  const { register, errors, handleSubmit, setValue, setError, clearErrors } = useForm();

  const [isVisible, setIsVisible] = useState(false);
  const [initialSlider, setInitialSlider] = useState(null);
  const [image, setImage] = useState('');
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);

  const dispatch = useDispatch();

  useImperativeHandle(ref, () => ({
    showForm,
    showFormWithInitialData,
  }));

  useEffect(() => {
    if (initialSlider) {
      setTimeout(() => {
        setValue('url', initialSlider.url);
        setImage(`${APP_URL}/${initialSlider.image}`);
      }, 100);
    }
  }, [initialSlider]);

  const showForm = () => setIsVisible(true);
  const closeForm = () => {
    setIsVisible(false);
    setInitialSlider(null);
    setImage('');
    setIsSubmittingForm(false);
  };

  const showFormWithInitialData = (data) => {
    setInitialSlider(data);
    showForm();
  };

  const onImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const uploadedFile = e.target.files[0];

      if (uploadedFile.type.indexOf('image') === -1) {
        setError('image', {
          type: 'manual',
          message: 'Image must be an image (jpg, png, gif, svg, or webp)',
        });
        return;
      }

      if (uploadedFile.size > 2000000) {
        setError('image', {
          type: 'manual',
          message: "Image size can't be larger than 2 MB",
        });
        return;
      }

      clearErrors('image');
      setImage(URL.createObjectURL(e.target.files[0]));
    }
  };

  const onSubmitForm = (data) => {
    setIsSubmittingForm(true);

    const formData = new FormData();
    formData.append('url', data.url);
    if (data.image.length > 0) formData.append('image', data.image[0]);

    if (initialSlider) {
      dispatch(updateSlider(initialSlider.id, formData))
        .then(() => closeForm())
        .catch(() => setIsSubmittingForm(false));
    } else {
      dispatch(submitSlider(formData))
        .then(() => closeForm())
        .catch(() => setIsSubmittingForm(false));
    }
  };

  return (
    <Modal
      isVisible={isVisible}
      ModalContent={
        <div className="sm:flex sm:items-start">
          <div className="w-full mt-3 text-center sm:mt-0 sm:text-left">
            <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
              {initialSlider ? 'Ubah Slider' : 'Tambah Slider Baru'}
            </Dialog.Title>
            <div className="mt-5">
              <form id="createForm" onSubmit={handleSubmit(onSubmitForm)}>
                <img src={image} alt="" className="mb-2" />

                <InlineTextInput
                  type="file"
                  accept="image/*"
                  title="Image"
                  inputRef={register({ required: initialSlider ? false : true })}
                  name="image"
                  onChange={onImageChange}
                  errorMessage={
                    errors.image &&
                    (errors.image.type === 'manual'
                      ? errors.image.message
                      : 'Kolom Image harus diisi')
                  }
                />
                <div className="-mt-3 mb-4 text-right text-xs">
                  Image size must be 1440x500, Max 2 MB &emsp;
                </div>

                <InlineTextInput
                  type="url"
                  title="URL"
                  inputRef={register({ required: true })}
                  name="url"
                  errorMessage={errors.url && 'Kolom URL harus diisi'}
                />
              </form>
            </div>
          </div>
        </div>
      }
      ModalButton={
        <>
          <SubmitButton
            type="submit"
            form="createForm"
            text="Submit"
            textClass="text-white text-xs"
            isLoading={isSubmittingForm}
          />
          <BasicButton text="Cancel" textClass="text-white text-xs mr-2" onClick={closeForm} />
        </>
      }
    />
  );
}

export default forwardRef(SliderCreate);
