import {
  FETCH_TECHNICIAN,
  SUBMIT_TECHNICIAN,
  DELETE_TECHNICIAN,
  REFRESH_TECHNICIAN_DATATABLES,
  RESET_STORE,
} from '../actions/_types';

const initialState = {
  result: [],
  submitResult: {},
  deleteResult: {},

  refresh: false,
};

export default function technician(state = initialState, action) {
  switch (action.type) {
    case FETCH_TECHNICIAN:
      return {
        ...state,
        result: action.result,
      };

    case SUBMIT_TECHNICIAN:
      return {
        ...state,
        submitResult: action.result,
      };

    case DELETE_TECHNICIAN:
      return {
        ...state,
        deleteResult: action.result,
      };

    case REFRESH_TECHNICIAN_DATATABLES:
      return {
        ...state,
        refresh: !state.refresh,
      };

    case RESET_STORE:
      return { ...initialState };

    default:
      return state;
  }
}
