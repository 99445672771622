export const PRODUCT_CATEGORY_TYPES = {
  PHONE: 'Handphone & Tablet',
  LAPTOP: 'Komputer & Laptop',
  ACCESSORIES: 'Accessories',
};

export const USER_GENDER = {
  Male: { label: 'Laki-Laki', value: 'Male' },
  Female: { label: 'Perempuan', value: 'Female' },
};

export const STATUS_STRING = {
  1: '<span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">Active</span>',
  0: '<span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">Disabled</span>',
};

export const SERVICE_STATUS_STRING = {
  0: '<span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">Pending</span>',
  1: '<span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">On Progress</span>',
  2: '<span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">Done</span>',
};

export const PAYMENT_FOOTER =
  'Untuk pembayaran dengan metode Bank Transfer, mohon melakukan pembayaran dengan cara transfer ke rekening <strong>BCA 555 555 5555 a.n. BSTORE INDONESIA</strong>';
