import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchWarehouse } from '../../actions/warehouse';
import { filterByWarehouse } from '../../actions/filter';

export default function FilterWarehouse() {
  const { result: authenticatedUser } = useSelector((state) => state.authenticatedUser);
  const { filterWarehouse } = useSelector((state) => state.filter);
  const { result: warehouses } = useSelector((state) => state.warehouse);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchWarehouse());
  }, [dispatch]);

  const onChange = (e) => {
    const selected = warehouses.find((w) => w.id === Number(e.target.value));
    dispatch(filterByWarehouse(selected));
  };

  return (
    <div className="mb-2 w-full relative flex items-center">
      <div className="w-full md:w-1/4 uppercase text-gray-800 text-xs font-bold">Store</div>
      <div className="flex-1 text-sm">
        <select
          className={`px-2 py-3 w-full placeholder-gray-400 text-gray-700 text-sm bg-white
            rounded shadow focus:outline-none focus:shadow-outline ease-linear transition-all duration-150`}
          onChange={onChange}
          value={filterWarehouse ? filterWarehouse.id : null}>
          {!authenticatedUser.warehouse_id && <option value="">Semua Store</option>}
          {warehouses.map((w) => (
            <option key={w.id} value={w.id}>
              {w.name}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}
