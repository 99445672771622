import { SUBMIT_SIGN_IN, RESET_STORE } from '../actions/_types';

const initialState = {
  submitResult: {},
};

export default function auth(state = initialState, action) {
  switch (action.type) {
    case SUBMIT_SIGN_IN:
      return {
        ...state,
        submitResult: action.result,
      };

    case RESET_STORE:
      return {
        ...state,
        submitResult: {},
      };

    default:
      return state;
  }
}
