import React, { useState, useEffect } from 'react';
import QRCode from 'qrcode.react';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';

import Collapsible from '../../components/Collapsible';
import { Spinner, InlineTextArea, SubmitButton } from '../../components/Base';
import {
  fetchWhatsappQr,
  fetchWhatsappTemplate,
  submitWhatsappTemplate,
} from '../../actions/whatsapp';

export default function Whatsapp() {
  const { register, errors, handleSubmit } = useForm();

  const { result, qrResult } = useSelector((state) => state.whatsapp);
  const dispatch = useDispatch();

  const [isLoadingData, setIsLoadingData] = useState(true);
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);

  useEffect(() => {
    dispatch(fetchWhatsappQr())
      .then(() => dispatch(fetchWhatsappTemplate()))
      .then(() => setIsLoadingData(false));
  }, []);

  const onSubmitForm = (data) => {
    setIsSubmittingForm(true);

    dispatch(submitWhatsappTemplate(data))
      .then(() => setIsSubmittingForm(false))
      .catch(() => setIsSubmittingForm(false));
  };

  return (
    <>
      <div className="relative flex flex-col min-w-0 w-full h-full bg-white mb-8 shadow-lg rounded-xl">
        {/* Header */}
        <div className="flex flex-wrap items-center py-3 border-b">
          <div className="px-4 flex-1">
            <h3 className="font-semibold text-gray-800 pl-2">Whatsapp Content Settings</h3>
          </div>
        </div>

        <div className="p-6">
          <Collapsible title="WhatsApp Login">
            <div className="flex flex-col items-center py-3">
              {isLoadingData ? (
                <Spinner />
              ) : qrResult.isAuthenticated ? (
                <h4>You are already authenticated to Whatsapp</h4>
              ) : (
                <>
                  <h4 className="mb-5">Please scan the QR Code using your Whatsapp Apps</h4>
                  <QRCode value={qrResult.qr} size={256} />
                </>
              )}
            </div>
          </Collapsible>

          <Collapsible title="WhatsApp Template">
            <form onSubmit={handleSubmit(onSubmitForm)}>
              <InlineTextArea
                title="Order Notification"
                inputRef={register({ required: true })}
                name="order_content_template"
                errorMessage={
                  errors.order_content_template && 'Kolom Order Notification harus diisi'
                }
                rows={8}
                defaultValue={result.order_content_template}
              />
              <div className="relative w-full -mt-4 mb-10">
                <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-1 md:gap-4 items-center">
                  <div></div>
                  <div className="col-span-2 xl:col-span-3 text-xs italic">
                    Tag Template yang dapat digunakan: [[OrderNo]], [[OrderLink]]
                  </div>
                </div>
              </div>

              <InlineTextArea
                title="Payment Notification"
                inputRef={register({ required: true })}
                name="payment_content_template"
                errorMessage={
                  errors.payment_content_template && 'Kolom Payment Notification harus diisi'
                }
                rows={3}
                defaultValue={result.payment_content_template}
              />
              <div className="relative w-full -mt-4 mb-10">
                <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-1 md:gap-4 items-center">
                  <div></div>
                  <div className="col-span-2 xl:col-span-3 text-xs italic">
                    Tag Template yang dapat digunakan: [[OrderNo]], [[OrderLink]]
                  </div>
                </div>
              </div>

              <InlineTextArea
                title="Shipping Notification"
                inputRef={register({ required: true })}
                name="shipping_content_template"
                errorMessage={
                  errors.shipping_content_template && 'Kolom Shipping Notification harus diisi'
                }
                rows={8}
                defaultValue={result.shipping_content_template}
              />
              <div className="relative w-full -mt-4 mb-10">
                <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-1 md:gap-4 items-center">
                  <div></div>
                  <div className="col-span-2 xl:col-span-3 text-xs italic">
                    Tag Template yang dapat digunakan: [[OrderNo]], [[OrderLink]],
                    [[NamaEkspedisi]], [[NoResi]]
                  </div>
                </div>
              </div>

              <InlineTextArea
                title="Review Notification"
                inputRef={register({ required: true })}
                name="review_content_template"
                errorMessage={
                  errors.review_content_template && 'Kolom Review Notification harus diisi'
                }
                rows={3}
                defaultValue={result.review_content_template}
              />
              <div className="relative w-full -mt-4 mb-10">
                <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-1 md:gap-4 items-center">
                  <div></div>
                  <div className="col-span-2 xl:col-span-3 text-xs italic">
                    Tag Template yang dapat digunakan: [[OrderNo]], [[OrderLink]]
                  </div>
                </div>
              </div>

              <SubmitButton
                type="submit"
                text="Save Template"
                textClass="text-white text-xs"
                isLoading={isSubmittingForm}
              />
            </form>
          </Collapsible>
        </div>
      </div>
    </>
  );
}
