import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Dialog } from '@headlessui/react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { Modal, InlineTextInput, BasicButton, SubmitButton } from '../../../components/Base';
import Collapsible from '../../../components/Collapsible';

import { updateProductStock } from '../../../actions/product';

function ProductStock(props, ref) {
  const { register, errors, handleSubmit } = useForm();

  const [isVisible, setIsVisible] = useState(false);
  const [initialProduct, setInitialProduct] = useState(null);
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);

  const dispatch = useDispatch();

  useImperativeHandle(ref, () => ({
    showForm,
    showFormWithInitialData,
  }));

  const showForm = () => setIsVisible(true);
  const closeForm = () => {
    setIsVisible(false);
    setInitialProduct(null);
    setIsSubmittingForm(false);
  };

  const showFormWithInitialData = (data) => {
    setInitialProduct(data);
    showForm();
  };

  const onSubmitForm = (data) => {
    setIsSubmittingForm(true);

    if (initialProduct) {
      dispatch(updateProductStock(initialProduct.id, data))
        .then(() => closeForm())
        .catch(() => setIsSubmittingForm(false));
    }
  };

  return (
    <Modal
      isVisible={isVisible}
      size="large"
      ModalContent={
        <div className="sm:flex sm:items-start">
          <div className="w-full mt-3 text-center sm:mt-0 sm:text-left">
            <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
              Stock Produk
            </Dialog.Title>
            <div className="mt-5">
              <form id="stockForm" onSubmit={handleSubmit(onSubmitForm)}>
                {/* Product Stock with No Variant */}
                {initialProduct &&
                  initialProduct.product_stocks.length > 0 &&
                  initialProduct.product_stocks.map((stock, index) => (
                    <div key={stock.id}>
                      <InlineTextInput
                        type="hidden"
                        inputRef={register({ required: true })}
                        name={`product_stocks[${index}].id`}
                        defaultValue={stock.id}
                      />
                      <InlineTextInput
                        type="number"
                        title={stock.warehouse_name}
                        inputRef={register({ required: true })}
                        name={`product_stocks[${index}].stock`}
                        defaultValue={stock.stock}
                        errorMessage={
                          errors.product_stocks &&
                          errors.product_stocks[index].stock &&
                          'Kolom Stock Warehouse harus diisi'
                        }
                      />
                    </div>
                  ))}

                {/* Product Stock with Variant */}
                {initialProduct &&
                  initialProduct.product_variants.length > 0 &&
                  initialProduct.product_variants.map((productVariant, vIndex) => (
                    <Collapsible key={productVariant.id} title={productVariant.name}>
                      {productVariant.product_stocks.length > 0 &&
                        productVariant.product_stocks.map((stock, index) => (
                          <div key={stock.id}>
                            <InlineTextInput
                              type="hidden"
                              inputRef={register({ required: true })}
                              name={`product_stocks[${
                                index + vIndex * productVariant.product_stocks.length
                              }].id`}
                              defaultValue={stock.id}
                            />
                            <InlineTextInput
                              type="number"
                              title={stock.warehouse_name}
                              inputRef={register({ required: true })}
                              name={`product_stocks[${
                                index + vIndex * productVariant.product_stocks.length
                              }].stock`}
                              defaultValue={stock.stock}
                              errorMessage={
                                errors.product_stocks &&
                                errors.product_stocks[
                                  index + vIndex * productVariant.product_stocks.length
                                ].stock &&
                                'Kolom Stock Warehouse harus diisi'
                              }
                            />
                          </div>
                        ))}
                    </Collapsible>
                  ))}
              </form>
            </div>
          </div>
        </div>
      }
      ModalButton={
        <>
          <SubmitButton
            type="submit"
            form="stockForm"
            text="Submit"
            textClass="text-white text-xs"
            isLoading={isSubmittingForm}
          />
          <BasicButton text="Cancel" textClass="text-white text-xs mr-2" onClick={closeForm} />
        </>
      }
    />
  );
}

export default forwardRef(ProductStock);
