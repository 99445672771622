import React from 'react';

import FilterRangeDate from '../Filter/FilterRangeDate';
import FilterWarehouse from '../Filter/FilterWarehouse';
import OrderSummaries from './OrderSummaries';
import OrderStatistics from './OrderStatistics';

export default function Dashboard() {
  return (
    <div>
      <div className="w-full md:w-1/2 mb-5">
        <FilterRangeDate />
        <FilterWarehouse />
      </div>

      <OrderSummaries />

      <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
        <div className="col-span-4">
          <OrderStatistics />
        </div>
      </div>
    </div>
  );
}
