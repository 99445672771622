import Swal from 'sweetalert2';
import {
  FETCH_PRODUCT_CATEGORY,
  FETCH_FEATURED_PRODUCT_CATEGORY,
  SUBMIT_PRODUCT_CATEGORY,
  DELETE_PRODUCT_CATEGORY,
  REFRESH_PRODUCT_CATEGORY_DATATABLES,
} from './_types';
import { API_URL, API_RESPONSE_STATUS, API_RESPONSE_MESSAGE, API_RESPONSE } from '../constants/env';
import { authApiHeader, authApiHeaderMultipart } from '../helpers/apiHeader';

export const fetchProductCategory = () => {
  return (dispatch) => {
    return fetch(`${API_URL}/product-category`, {
      method: 'GET',
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            dispatch({ type: FETCH_PRODUCT_CATEGORY, result: response.data });
            break;

          case API_RESPONSE.FAILED:
          default:
            dispatch({ type: FETCH_PRODUCT_CATEGORY, result: [] });
            Swal.fire('Fetch Product Category Failed', response[API_RESPONSE_MESSAGE], 'error');
        }
      });
  };
};

export const fetchFeaturedProductCategory = () => {
  return (dispatch) => {
    return fetch(`${API_URL}/product-category?active=true&featured=true`, {
      method: 'GET',
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            dispatch({ type: FETCH_FEATURED_PRODUCT_CATEGORY, result: response.data });
            break;

          case API_RESPONSE.FAILED:
          default:
            dispatch({ type: FETCH_FEATURED_PRODUCT_CATEGORY, result: [] });
            Swal.fire('Fetch Product Category Failed', response[API_RESPONSE_MESSAGE], 'error');
        }
      });
  };
};

export const refreshProductCategoryDatatables = () => {
  return (dispatch) => {
    dispatch({ type: REFRESH_PRODUCT_CATEGORY_DATATABLES });
  };
};

/**
 * @param data      FormData: name, url_slug, type, image, is_featured, status, description,
 *                    meta_title, meta_description, meta_keywords
 */
export const submitProductCategory = (data) => {
  return (dispatch) => {
    return fetch(`${API_URL}/product-category`, {
      method: 'POST',
      body: data,
      headers: authApiHeaderMultipart(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            Swal.fire('Submit Success', response[API_RESPONSE_MESSAGE], 'success');
            dispatch({ type: SUBMIT_PRODUCT_CATEGORY, result: response });
            break;

          case API_RESPONSE.FAILED:
          default:
            Swal.fire('Submit Failed', response[API_RESPONSE_MESSAGE], 'error');
            return Promise.reject(response[API_RESPONSE_MESSAGE]);
        }
      })
      .then(() => dispatch(refreshProductCategoryDatatables()))
      .then(() => dispatch(fetchProductCategory()));
  };
};

/**
 * @param id
 * @param data      FormData: name, url_slug, type, image, is_featured, status, description,
 *                    meta_title, meta_description, meta_keywords
 */
export const updateProductCategory = (id, data) => {
  return (dispatch) => {
    data.append('_method', 'PUT');

    return fetch(`${API_URL}/product-category/${id}`, {
      method: 'POST',
      body: data,
      headers: authApiHeaderMultipart(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            Swal.fire('Submit Success', response[API_RESPONSE_MESSAGE], 'success');
            dispatch({ type: SUBMIT_PRODUCT_CATEGORY, result: response });
            break;

          case API_RESPONSE.FAILED:
          default:
            Swal.fire('Submit Failed', response[API_RESPONSE_MESSAGE], 'error');
            return Promise.reject(response[API_RESPONSE_MESSAGE]);
        }
      })
      .then(() => dispatch(refreshProductCategoryDatatables()))
      .then(() => dispatch(fetchProductCategory()));
  };
};

/**
 * @param id
 */
export const deleteProductCategory = (id) => {
  return (dispatch) => {
    return fetch(`${API_URL}/product-category/${id}`, {
      method: 'DELETE',
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            Swal.fire('Delete Success', response[API_RESPONSE_MESSAGE], 'success');
            dispatch({ type: DELETE_PRODUCT_CATEGORY, result: response });
            break;

          case API_RESPONSE.FAILED:
          default:
            Swal.fire('Delete Failed', response[API_RESPONSE_MESSAGE], 'error');
            return Promise.reject(response[API_RESPONSE_MESSAGE]);
        }
      })
      .then(() => dispatch(refreshProductCategoryDatatables()))
      .then(() => dispatch(fetchProductCategory()));
  };
};
