import React, { useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useSelector } from 'react-redux';

import $ from 'jquery';

import PaymentMethodCreate from './Create';
import { SubmitButton } from '../../components/Base';
import { API_URL, DATATABLES_DEFAULT_CONFIG } from '../../constants/env';
import { STATUS_STRING } from '../../constants/data';
import Utils from '../../helpers/utils';
// import { deletePaymentMethod } from '../../../actions/paymentMethod';

export default function PaymentMethod() {
  const paymentMethodFormRef = useRef();

  const { result: authenticatedUser } = useSelector((state) => state.authenticatedUser);
  const { refresh } = useSelector((state) => state.paymentMethod);
  // const dispatch = useDispatch();

  useEffect(() => {
    initializeDataTables();
  }, [refresh]);

  const initializeDataTables = () => {
    $('#paymentMethodDataTable').DataTable().destroy();
    $('#paymentMethodDataTable').DataTable({
      ...DATATABLES_DEFAULT_CONFIG,
      ajax: {
        url: `${API_URL}/payment-method?source=datatables`,
        dataSrc: 'data',
        beforeSend: (xhr) =>
          xhr.setRequestHeader('Authorization', `Bearer ${authenticatedUser.token}`),
      },
      columns: [
        { data: 'name' },
        { data: 'description' },
        {
          data: 'fee_amount',
          render: (data, type, row) =>
            `${Utils.numberFormat(data, 2)}${row.fee_type === 'Percentage' ? '%' : ''}`,
        },
        {
          data: 'status',
          render: (data) => STATUS_STRING[data],
        },
        {
          data: 'id',
          searchable: false,
          sortable: false,
          createdCell: (cell, cellData, rowData) =>
            ReactDOM.render(
              <div>
                <SubmitButton
                  color="blue"
                  textClass="text-white text-xs"
                  paddingClass="px-3 py-2 mr-1"
                  text="Edit"
                  onClick={() => onPressEdit(rowData)}
                />
              </div>,
              cell,
            ),
        },
      ],
    });
  };

  // const onPressCreate = () => paymentMethodFormRef.current.showForm();
  const onPressEdit = (data) => paymentMethodFormRef.current.showFormWithInitialData(data);
  // const onPressDelete = data => dispatch(deletePaymentMethod(data.id));

  return (
    <>
      <div className="relative flex flex-col min-w-0 w-full h-full bg-white mb-8 shadow-lg rounded-xl">
        {/* Header */}
        <div className="flex flex-wrap items-center py-3 border-b">
          <div className="px-4 flex-1">
            <h3 className="font-semibold text-gray-800 pl-2">Payment Method</h3>
          </div>
          <div className="px-4">
            {/* <SubmitButton
              textClass="text-white text-xs"
              text="Tambah PaymentMethod"
              onClick={onPressCreate}
            /> */}
            <PaymentMethodCreate ref={paymentMethodFormRef} />
          </div>
        </div>

        {/* Table Content */}
        <div className="block w-full overflow-x-auto p-6">
          {/* Projects table */}
          <table
            id="paymentMethodDataTable"
            className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-semibold text-left">
                  Nama
                </th>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-semibold text-left">
                  Deskripsi
                </th>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-semibold text-left">
                  Fee
                </th>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-semibold text-left">
                  Status
                </th>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-semibold text-left w-40"></th>
              </tr>
            </thead>
            <tbody></tbody>
          </table>
        </div>
      </div>
    </>
  );
}
