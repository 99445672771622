import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Spinner } from '../../components/Base';
import Utils from '../../helpers/utils';

import { fetchOrderSummaries } from '../../actions/dashboard';

export default function OrderSummaries() {
  const { filterDate } = useSelector((state) => state.filter);
  const { orderSummaries } = useSelector((state) => state.dashboard);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchOrderSummaries(filterDate.startDate, filterDate.endDate));
  }, [filterDate]);

  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
      <div className="mb-8 p-4 relative bg-white shadow-lg rounded-lg">
        <h4 className="font-light uppercase text-sm">Total Sales</h4>
        {orderSummaries.order_total ? (
          <div className="mt-1 text-purple-800 text-4xl">
            {Utils.numberFormat(orderSummaries.order_total, 2)}
          </div>
        ) : (
          <Spinner />
        )}
      </div>

      <div className="mb-8 p-4 relative bg-white shadow-lg rounded-lg">
        <h4 className="font-light uppercase text-sm">Total Order</h4>
        {orderSummaries.order_count || orderSummaries.order_count === 0 ? (
          <div className="mt-1 text-purple-800 text-4xl">
            {Utils.numberFormat(orderSummaries.order_count, 2)}
          </div>
        ) : (
          <Spinner />
        )}
      </div>

      <div className="mb-8 p-4 relative bg-white shadow-lg rounded-lg">
        <h4 className="font-light uppercase text-sm">Average Order Sale</h4>
        {orderSummaries.order_average ? (
          <div className="mt-1 text-purple-800 text-4xl">
            {Utils.numberFormat(orderSummaries.order_average, 2)}
          </div>
        ) : (
          <div className="pt-1">
            <Spinner />
          </div>
        )}
      </div>
    </div>
  );
}
