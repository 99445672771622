import React, { forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import { Dialog } from '@headlessui/react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import {
  Modal,
  InlineTextInput,
  InlineSwitch,
  BasicButton,
  SubmitButton,
} from '../../../components/Base';
import { submitShippingMethod, updateShippingMethod } from '../../../actions/shippingMethod';

function ShippingMethodCreate(props, ref) {
  const { register, errors, handleSubmit, setValue } = useForm();

  const [isVisible, setIsVisible] = useState(false);
  const [initialShippingMethod, setInitialShippingMethod] = useState(null);
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);

  const dispatch = useDispatch();

  useImperativeHandle(ref, () => ({
    showForm,
    showFormWithInitialData,
  }));

  useEffect(() => {
    if (initialShippingMethod) {
      setTimeout(() => {
        setValue('name', initialShippingMethod.name);
        setValue('additional_fee', initialShippingMethod.additional_fee);
        setValue('insurance_fee_percentage', initialShippingMethod.insurance_fee_percentage);
        setValue('insurance_fee_amount', initialShippingMethod.insurance_fee_amount);
        setValue('status', initialShippingMethod.status === 1);
      }, 100);
    }
  }, [initialShippingMethod]);

  const showForm = () => setIsVisible(true);
  const closeForm = () => {
    setIsVisible(false);
    setInitialShippingMethod(null);
    setIsSubmittingForm(false);
  };

  const showFormWithInitialData = (data) => {
    setInitialShippingMethod(data);
    showForm();
  };

  const onSubmitForm = (data) => {
    setIsSubmittingForm(true);

    data.status = data.status ? 1 : 0;

    if (initialShippingMethod) {
      dispatch(updateShippingMethod(initialShippingMethod.id, data))
        .then(() => closeForm())
        .catch(() => setIsSubmittingForm(false));
    } else {
      dispatch(submitShippingMethod(data))
        .then(() => closeForm())
        .catch(() => setIsSubmittingForm(false));
    }
  };

  return (
    <Modal
      isVisible={isVisible}
      size="large"
      ModalContent={
        <div className="sm:flex sm:items-start">
          <div className="w-full mt-3 text-center sm:mt-0 sm:text-left">
            <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
              {initialShippingMethod ? 'Ubah Shipping Method' : 'Tambah Shipping Method Baru'}
            </Dialog.Title>
            <div className="mt-5">
              <form id="createForm" onSubmit={handleSubmit(onSubmitForm)}>
                <InlineTextInput
                  type="text"
                  title="Nama"
                  // inputRef={register({ required: true })}
                  // name="name"
                  // errorMessage={errors.name && 'Kolom Nama harus diisi'}
                  value={initialShippingMethod ? initialShippingMethod.name : ''}
                  disabled
                  readonly
                />

                <InlineTextInput
                  type="number"
                  title="Biaya Tambahan"
                  inputRef={register({ required: true })}
                  name="additional_fee"
                  errorMessage={errors.additional_fee && 'Kolom Biaya Tambahan harus diisi'}
                />

                <br />

                <InlineTextInput
                  type="number"
                  title="Biaya Asuransi (%)"
                  inputRef={register({ required: true })}
                  name="insurance_fee_percentage"
                  min="0"
                  step=".01"
                  errorMessage={
                    errors.insurance_fee_percentage && 'Kolom Biaya Asuransi (%) harus diisi'
                  }
                />

                <div className="pl-5">
                  <InlineTextInput
                    type="number"
                    title="+ Biaya Asuransi (Rp)"
                    inputRef={register({ required: true })}
                    name="insurance_fee_amount"
                    min="0"
                    errorMessage={
                      errors.insurance_fee_amount && 'Kolom Biaya Asuransi (Rp) harus diisi'
                    }
                  />
                </div>

                <br />

                <InlineSwitch
                  title="Status"
                  inputRef={register()}
                  name="status"
                  defaultChecked={true}
                />
              </form>
            </div>
          </div>
        </div>
      }
      ModalButton={
        <>
          <SubmitButton
            type="submit"
            form="createForm"
            text="Submit"
            textClass="text-white text-xs"
            isLoading={isSubmittingForm}
          />
          <BasicButton text="Cancel" textClass="text-white text-xs mr-2" onClick={closeForm} />
        </>
      }
    />
  );
}

export default forwardRef(ShippingMethodCreate);
