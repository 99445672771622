import Swal from 'sweetalert2';
import { FETCH_DASHBOARD_ORDER_SUMMARIES, FETCH_DASHBOARD_ORDER_STATISTICS } from './_types';
import { API_URL, API_RESPONSE_STATUS, API_RESPONSE_MESSAGE, API_RESPONSE } from '../constants/env';
import { authApiHeader } from '../helpers/apiHeader';

export const fetchOrderSummaries = (startDate, endDate) => {
  return (dispatch) => {
    return fetch(`${API_URL}/dashboard/order-summaries?s=${startDate}&e=${endDate}`, {
      method: 'GET',
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            dispatch({ type: FETCH_DASHBOARD_ORDER_SUMMARIES, result: response.data });
            break;

          case API_RESPONSE.FAILED:
          default:
            dispatch({ type: FETCH_DASHBOARD_ORDER_SUMMARIES, result: {} });
            Swal.fire('Fetch Statistics Failed', response[API_RESPONSE_MESSAGE], 'error');
        }
      });
  };
};

export const fetchOrderStatistics = (startDate, endDate) => {
  return (dispatch) => {
    return fetch(`${API_URL}/dashboard/order-statistics?s=${startDate}&e=${endDate}`, {
      method: 'GET',
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            dispatch({ type: FETCH_DASHBOARD_ORDER_STATISTICS, result: response.data });
            break;

          case API_RESPONSE.FAILED:
          default:
            dispatch({ type: FETCH_DASHBOARD_ORDER_STATISTICS, result: [] });
            Swal.fire('Fetch Statistics Failed', response[API_RESPONSE_MESSAGE], 'error');
        }
      });
  };
};
