import { SUBMIT_CHANGE_PASSWORD, RESET_STORE } from '../actions/_types';

const initialState = {
  submitResult: {},
};

export default function password(state = initialState, action) {
  switch (action.type) {
    case SUBMIT_CHANGE_PASSWORD:
      return {
        ...state,
        submitResult: action.result,
      };

    case RESET_STORE:
      return { ...initialState };

    default:
      return state;
  }
}
