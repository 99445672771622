import React, { forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import { Dialog } from '@headlessui/react';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';

import {
  Modal,
  InlineSelect,
  InlineTextInput,
  BasicButton,
  SubmitButton,
} from '../../../components/Base';
import { USER_GENDER } from '../../../constants/data';
import { fetchRole } from '../../../actions/role';
import { submitAdmin, updateAdmin } from '../../../actions/admin';

function AdminCreate(props, ref) {
  const { register, errors, handleSubmit, setValue } = useForm();

  const [isVisible, setIsVisible] = useState(false);
  const [initialAdmin, setInitialAdmin] = useState(null);
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);

  const { result: roles } = useSelector((state) => state.role);
  const { result: warehouses } = useSelector((state) => state.warehouse);
  const dispatch = useDispatch();

  useImperativeHandle(ref, () => ({
    showForm,
    showFormWithInitialData,
  }));

  useEffect(() => {
    dispatch(fetchRole());
  }, []);

  useEffect(() => {
    if (initialAdmin) {
      setTimeout(() => {
        setValue('first_name', initialAdmin.first_name);
        setValue('last_name', initialAdmin.last_name);
        setValue('role_id', initialAdmin.role_id);
        setValue('gender', initialAdmin.gender);
      }, 100);
    }
  }, [initialAdmin]);

  const showForm = () => setIsVisible(true);
  const closeForm = () => {
    setIsVisible(false);
    setInitialAdmin(null);
    setIsSubmittingForm(false);
  };

  const showFormWithInitialData = (data) => {
    setInitialAdmin(data);
    showForm();
  };

  const onSubmitForm = (data) => {
    setIsSubmittingForm(true);

    if (initialAdmin) {
      if (data.password.trim() === '') delete data.password;

      dispatch(updateAdmin(initialAdmin.id, data))
        .then(() => closeForm())
        .catch(() => setIsSubmittingForm(false));
    } else {
      dispatch(submitAdmin(data))
        .then(() => closeForm())
        .catch(() => setIsSubmittingForm(false));
    }
  };

  return (
    <Modal
      isVisible={isVisible}
      ModalContent={
        <div className="sm:flex sm:items-start">
          <div className="w-full mt-3 text-center sm:mt-0 sm:text-left">
            <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
              {initialAdmin ? 'Ubah Admin' : 'Tambah Admin Baru'}
            </Dialog.Title>
            <div className="mt-5">
              <form id="createForm" onSubmit={handleSubmit(onSubmitForm)}>
                {initialAdmin && (
                  <div className="relative w-full mb-5">
                    <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-1 md:gap-4 items-center">
                      <label className="block uppercase text-gray-700 text-xs font-bold">
                        Phone
                      </label>
                      <div className="col-span-2 xl:col-span-3">
                        <div className="relative flex flex-wrap items-stretch">
                          {initialAdmin.phone}
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <InlineTextInput
                  type="text"
                  title="First Name*"
                  inputRef={register({ required: true })}
                  name="first_name"
                  errorMessage={errors.first_name && 'Kolom First Name harus diisi'}
                />

                <InlineTextInput
                  type="text"
                  title="Last Name"
                  inputRef={register()}
                  name="last_name"
                />

                {!initialAdmin && (
                  <InlineTextInput
                    type="text"
                    title="Phone*"
                    inputRef={register({ required: true })}
                    name="phone"
                    errorMessage={errors.phone && 'Kolom Phone harus diisi'}
                  />
                )}

                <InlineTextInput
                  type="password"
                  title={initialAdmin ? 'Password' : 'Password*'}
                  inputRef={register({ required: initialAdmin ? false : true })}
                  name="password"
                  errorMessage={errors.password && 'Kolom Password harus diisi'}
                />
                {initialAdmin && (
                  <div className="-mt-3 mb-4 text-xs">
                    Silahkan masukan Password baru untuk mengubah Password
                  </div>
                )}

                <InlineSelect
                  title="Role*"
                  inputRef={register({ required: true })}
                  name="role_id"
                  options={roles.map((role) => (
                    <option key={role.id} value={role.id}>
                      {role.name}
                    </option>
                  ))}
                  errorMessage={errors.role_id && 'Kolom Role harus dipilih'}
                />

                <InlineSelect
                  title="Gender"
                  inputRef={register({})}
                  name="gender"
                  options={Object.values(USER_GENDER).map((gender) => (
                    <option key={gender.value} value={gender.value}>
                      {gender.label}
                    </option>
                  ))}
                />

                <InlineSelect
                  title="Store"
                  inputRef={register()}
                  name="warehouse_id"
                  options={warehouses.map((warehouse) => (
                    <option key={warehouse.id} value={warehouse.id}>
                      {warehouse.name}
                    </option>
                  ))}
                />
              </form>
            </div>
          </div>
        </div>
      }
      ModalButton={
        <>
          <SubmitButton
            type="submit"
            form="createForm"
            text="Submit"
            textClass="text-white text-xs"
            isLoading={isSubmittingForm}
          />
          <BasicButton text="Cancel" textClass="text-white text-xs mr-2" onClick={closeForm} />
        </>
      }
    />
  );
}

export default forwardRef(AdminCreate);
