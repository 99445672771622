import {
  FETCH_PRODUCT,
  FETCH_FEATURED_PRODUCT,
  FETCH_PAGINATED_PRODUCT,
  FETCH_PRODUCT_DETAIL,
  SUBMIT_PRODUCT,
  DELETE_PRODUCT,
  REFRESH_PRODUCT_DATATABLES,
  RESET_STORE,
} from '../actions/_types';

const initialState = {
  result: [],
  featuredResult: [],
  paginatedResult: {},

  detailResult: {},

  submitResult: {},
  deleteResult: {},

  refresh: false,
};

export default function product(state = initialState, action) {
  switch (action.type) {
    case FETCH_PRODUCT:
      return {
        ...state,
        result: action.result,
      };

    case FETCH_FEATURED_PRODUCT:
      return {
        ...state,
        featuredResult: action.result,
      };

    case FETCH_PAGINATED_PRODUCT:
      return {
        ...state,
        paginatedResult: action.result,
      };

    case FETCH_PRODUCT_DETAIL:
      return {
        ...state,
        detailResult: action.result,
      };

    case SUBMIT_PRODUCT:
      return {
        ...state,
        submitResult: action.result,
      };

    case DELETE_PRODUCT:
      return {
        ...state,
        deleteResult: action.result,
      };

    case REFRESH_PRODUCT_DATATABLES:
      return {
        ...state,
        refresh: !state.refresh,
      };

    case RESET_STORE:
      return { ...initialState };

    default:
      return state;
  }
}
