import Swal from 'sweetalert2';
import { FETCH_WHATSAPP_TEMPLATE, SUBMIT_WHATSAPP_TEMPLATE, FETCH_WHATSAPP_QR } from './_types';
import { API_URL, API_RESPONSE_STATUS, API_RESPONSE_MESSAGE, API_RESPONSE } from '../constants/env';
import { authApiHeader } from '../helpers/apiHeader';

export const fetchWhatsappTemplate = () => {
  return (dispatch) => {
    return fetch(`${API_URL}/whatsapp`, {
      method: 'GET',
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            dispatch({ type: FETCH_WHATSAPP_TEMPLATE, result: response.data });
            break;

          case API_RESPONSE.FAILED:
          default:
            dispatch({ type: FETCH_WHATSAPP_TEMPLATE, result: {} });
            Swal.fire('Fetch Whatsapp Failed', response[API_RESPONSE_MESSAGE], 'error');
        }
      });
  };
};

/**
 * @param data      order_content_template, payment_content_template, shipping_content_template, review_content_template
 */
export const submitWhatsappTemplate = (data) => {
  return (dispatch) => {
    return fetch(`${API_URL}/whatsapp`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            Swal.fire('Submit Success', response[API_RESPONSE_MESSAGE], 'success');
            dispatch({ type: SUBMIT_WHATSAPP_TEMPLATE, result: response });
            break;

          case API_RESPONSE.FAILED:
          default:
            Swal.fire('Submit Failed', response[API_RESPONSE_MESSAGE], 'error');
            return Promise.reject(response[API_RESPONSE_MESSAGE]);
        }
      });
  };
};

export const fetchWhatsappQr = () => {
  return (dispatch) => {
    return fetch(`${API_URL}/whatsapp/qr`, {
      method: 'GET',
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            dispatch({ type: FETCH_WHATSAPP_QR, result: response.data });
            break;

          case API_RESPONSE.FAILED:
          default:
            dispatch({ type: FETCH_WHATSAPP_QR, result: {} });
            Swal.fire('Fetch Whatsapp Failed', response[API_RESPONSE_MESSAGE], 'error');
        }
      });
  };
};
