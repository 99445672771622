import React, { forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import { Dialog } from '@headlessui/react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { Modal, InlineTextInput, BasicButton, SubmitButton } from '../../../components/Base';
import { submitTechnician, updateTechnician } from '../../../actions/technician';

function TechnicianCreate(props, ref) {
  const { register, errors, handleSubmit, setValue } = useForm();

  const [isVisible, setIsVisible] = useState(false);
  const [initialTechnician, setInitialTechnician] = useState(null);
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);

  const dispatch = useDispatch();

  useImperativeHandle(ref, () => ({
    showForm,
    showFormWithInitialData,
  }));

  useEffect(() => {
    if (initialTechnician) {
      setTimeout(() => {
        setValue('nik', initialTechnician.nik);
        setValue('name', initialTechnician.name);
      }, 100);
    }
  }, [initialTechnician]);

  const showForm = () => setIsVisible(true);
  const closeForm = () => {
    setIsVisible(false);
    setInitialTechnician(null);
    setIsSubmittingForm(false);
  };

  const showFormWithInitialData = (data) => {
    setInitialTechnician(data);
    showForm();
  };

  const onSubmitForm = (data) => {
    setIsSubmittingForm(true);

    if (initialTechnician) {
      dispatch(updateTechnician(initialTechnician.id, data))
        .then(() => closeForm())
        .catch(() => setIsSubmittingForm(false));
    } else {
      dispatch(submitTechnician(data))
        .then(() => closeForm())
        .catch(() => setIsSubmittingForm(false));
    }
  };

  return (
    <Modal
      isVisible={isVisible}
      ModalContent={
        <div className="sm:flex sm:items-start">
          <div className="w-full mt-3 text-center sm:mt-0 sm:text-left">
            <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
              {initialTechnician ? 'Ubah Technician' : 'Tambah Technician Baru'}
            </Dialog.Title>
            <div className="mt-5">
              <form id="createForm" onSubmit={handleSubmit(onSubmitForm)}>
                <InlineTextInput
                  type="text"
                  title="NIK"
                  inputRef={register({ required: true })}
                  name="nik"
                  errorMessage={errors.nik && 'Kolom NIK harus diisi'}
                />

                <InlineTextInput
                  type="text"
                  title="Nama Teknisi"
                  inputRef={register({ required: true })}
                  name="name"
                  errorMessage={errors.name && 'Kolom Nama Teknisi harus diisi'}
                />
              </form>
            </div>
          </div>
        </div>
      }
      ModalButton={
        <>
          <SubmitButton
            type="submit"
            form="createForm"
            text="Submit"
            textClass="text-white text-xs"
            isLoading={isSubmittingForm}
          />
          <BasicButton text="Cancel" textClass="text-white text-xs mr-2" onClick={closeForm} />
        </>
      }
    />
  );
}

export default forwardRef(TechnicianCreate);
