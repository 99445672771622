import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Login from './Login';

export default function Auth() {
  const { result: authenticatedUser } = useSelector((state) => state.authenticatedUser);
  return authenticatedUser && authenticatedUser.is_admin === 1 ? (
    <Redirect from="*" to="/" />
  ) : (
    <main>
      <section className="relative w-full h-full py-20 min-h-screen bg-gray-100">
        <Switch>
          <Route path="/login" exact component={Login} />
        </Switch>
      </section>
    </main>
  );
}
