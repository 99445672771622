import React, { useEffect } from 'react';
import Chart from 'chart.js';
import { useSelector, useDispatch } from 'react-redux';

import { fetchOrderStatistics } from '../../actions/dashboard';

export default function OrderStatistics() {
  const { filterDate } = useSelector((state) => state.filter);
  const { orderStatistics } = useSelector((state) => state.dashboard);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchOrderStatistics(filterDate.startDate, filterDate.endDate));
  }, [filterDate]);

  useEffect(() => {
    const config = {
      type: 'bar',
      data: {
        labels: orderStatistics.map((order) => order.label),
        datasets: [
          {
            label: 'Order Total',
            data: orderStatistics.map((order) => parseFloat(order.value)),
            backgroundColor: 'rgba(153, 102, 255, 0.2)',
            borderColor: 'rgb(153, 102, 255)',
            fill: false,
            borderWidth: 1,
          },
        ],
      },
      options: {
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      },
    };

    const ctx = document.getElementById('orderStatisticsChart').getContext('2d');
    window.myBar = new Chart(ctx, config);
  }, [orderStatistics]);

  return (
    <div className="mb-8 p-4 relative bg-white shadow-lg rounded-lg">
      <h4 className="font-light uppercase text-sm">Order Statistics</h4>
      <canvas id="orderStatisticsChart"></canvas>
    </div>
  );
}
