import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { MENUS } from '../constants/menu';

export default function PrivateRoleRoute(props) {
  const { result: authenticatedUser } = useSelector((state) => state.authenticatedUser);
  const permissions = authenticatedUser.role.permissions
    ? authenticatedUser.role.permissions.split(', ')
    : null;

  const menuChilds = [].concat
    .apply(
      [],
      MENUS.map((menu) => menu.childs),
    )
    .filter((menu) => permissions === null || permissions.indexOf(menu.id) >= 0);
  const isAllowed = menuChilds.find((menu) => menu.route === props.path);

  return isAllowed ? <Route {...props} /> : <Redirect from="*" to={menuChilds[0].route} />;
}
