import {
  FETCH_PRODUCT_CATEGORY,
  FETCH_FEATURED_PRODUCT_CATEGORY,
  SUBMIT_PRODUCT_CATEGORY,
  DELETE_PRODUCT_CATEGORY,
  REFRESH_PRODUCT_CATEGORY_DATATABLES,
  RESET_STORE,
} from '../actions/_types';

const initialState = {
  result: [],
  featuredResult: [],
  submitResult: {},
  deleteResult: {},

  refresh: false,
};

export default function productCategory(state = initialState, action) {
  switch (action.type) {
    case FETCH_PRODUCT_CATEGORY:
      return {
        ...state,
        result: action.result,
      };

    case FETCH_FEATURED_PRODUCT_CATEGORY:
      return {
        ...state,
        featuredResult: action.result,
      };

    case SUBMIT_PRODUCT_CATEGORY:
      return {
        ...state,
        submitResult: action.result,
      };

    case DELETE_PRODUCT_CATEGORY:
      return {
        ...state,
        deleteResult: action.result,
      };

    case REFRESH_PRODUCT_CATEGORY_DATATABLES:
      return {
        ...state,
        refresh: !state.refresh,
      };

    case RESET_STORE:
      return { ...initialState };

    default:
      return state;
  }
}
