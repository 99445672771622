import React, { forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import { Dialog } from '@headlessui/react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { Modal, InlineSwitch, BasicButton, SubmitButton } from '../../../components/Base';
import { updateReview } from '../../../actions/review';

function ReviewCreate(props, ref) {
  const { register, errors, handleSubmit, setValue } = useForm();

  const [isVisible, setIsVisible] = useState(false);
  const [initialReview, setInitialReview] = useState(null);
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);

  const dispatch = useDispatch();

  useImperativeHandle(ref, () => ({
    showForm,
    showFormWithInitialData,
  }));

  useEffect(() => {
    if (initialReview) {
      setTimeout(() => {
        setValue('is_public', initialReview.is_public === 1);
      }, 100);
    }
  }, [initialReview]);

  const showForm = () => setIsVisible(true);
  const closeForm = () => {
    setIsVisible(false);
    setInitialReview(null);
    setIsSubmittingForm(false);
  };

  const showFormWithInitialData = (data) => {
    setInitialReview(data);
    showForm();
  };

  const onSubmitForm = (data) => {
    setIsSubmittingForm(true);

    if (initialReview) {
      dispatch(updateReview(initialReview.id, data))
        .then(() => closeForm())
        .catch(() => setIsSubmittingForm(false));
    } else {
      /* dispatch(submitReview(data))
        .then(() => closeForm())
        .catch(() => setIsSubmittingForm(false)); */
    }
  };

  return (
    <Modal
      isVisible={isVisible}
      size="large"
      ModalContent={
        <div className="sm:flex sm:items-start">
          <div className="w-full mt-3 text-center sm:mt-0 sm:text-left">
            <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
              {initialReview ? 'Ubah Review' : 'Tambah Review Baru'}
            </Dialog.Title>
            <div className="mt-5">
              <form id="createForm" onSubmit={handleSubmit(onSubmitForm)}>
                <div className="relative w-full mb-2">
                  <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-1 md:gap-4 items-center">
                    <div className="block uppercase text-gray-700 text-xs font-bold">Star</div>
                    <div className="col-span-2 xl:col-span-3">
                      <span className="text-purple-600">★</span>
                      {initialReview?.star}
                    </div>
                  </div>
                </div>

                <div className="relative w-full mb-2">
                  <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-1 md:gap-4 items-center">
                    <div className="block uppercase text-gray-700 text-xs font-bold">Review</div>
                    <div className="col-span-2 xl:col-span-3">{initialReview?.review}</div>
                  </div>
                </div>

                <InlineSwitch
                  title="Public"
                  inputRef={register()}
                  name="is_public"
                  errorMessage={errors.is_public && 'Kolom Public harus dipilih'}
                />
              </form>
            </div>
          </div>
        </div>
      }
      ModalButton={
        <>
          <SubmitButton
            type="submit"
            form="createForm"
            text="Submit"
            textClass="text-white text-xs"
            isLoading={isSubmittingForm}
          />
          <BasicButton text="Cancel" textClass="text-white text-xs mr-2" onClick={closeForm} />
        </>
      }
    />
  );
}

export default forwardRef(ReviewCreate);
