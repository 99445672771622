import Swal from 'sweetalert2';
import { FETCH_PROVINCE, FETCH_CITY, FETCH_DISTRICT, FETCH_SHIPPING_FEE } from './_types';
import { API_URL, API_RESPONSE_STATUS, API_RESPONSE_MESSAGE, API_RESPONSE } from '../constants/env';
import { authApiHeader } from '../helpers/apiHeader';

const CUSTOM_API_URL = API_URL.replace('/backoffice', '');

export const fetchProvince = () => {
  return (dispatch) => {
    return fetch(`${CUSTOM_API_URL}/province`, {
      method: 'GET',
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            dispatch({ type: FETCH_PROVINCE, result: response.data });
            break;

          case API_RESPONSE.FAILED:
          default:
            dispatch({ type: FETCH_PROVINCE, result: [] });
            Swal.fire('Fetch Province Failed', response[API_RESPONSE_MESSAGE], 'error');
        }
      });
  };
};

export const fetchCity = (provinceId) => {
  return (dispatch) => {
    return fetch(`${CUSTOM_API_URL}/city?province=${provinceId}`, {
      method: 'GET',
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            dispatch({ type: FETCH_CITY, result: response.data });
            break;

          case API_RESPONSE.FAILED:
          default:
            dispatch({ type: FETCH_CITY, result: [] });
            Swal.fire('Fetch City Failed', response[API_RESPONSE_MESSAGE], 'error');
        }
      });
  };
};

export const fetchDistrict = (cityId) => {
  return (dispatch) => {
    return fetch(`${CUSTOM_API_URL}/district?city=${cityId}`, {
      method: 'GET',
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            dispatch({ type: FETCH_DISTRICT, result: response.data });
            break;

          case API_RESPONSE.FAILED:
          default:
            dispatch({ type: FETCH_DISTRICT, result: [] });
            Swal.fire('Fetch District Failed', response[API_RESPONSE_MESSAGE], 'error');
        }
      });
  };
};

export const fetchShippingFee = (origin, destination, weight, courier) => {
  return (dispatch) => {
    return fetch(
      `${CUSTOM_API_URL}/shipping-fee?origin=${origin}&destination=${destination}&weight=${weight}&courier=${courier}`,
      {
        method: 'GET',
        headers: authApiHeader(),
      },
    )
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            dispatch({ type: FETCH_SHIPPING_FEE, result: response.data });
            break;

          case API_RESPONSE.FAILED:
          default:
            dispatch({ type: FETCH_SHIPPING_FEE, result: [] });
            Swal.fire('Fetch Shipping Fee Failed', response[API_RESPONSE_MESSAGE], 'error');
        }
      });
  };
};

export const resetShippingFee = () => {
  return (dispatch) => {
    return dispatch({ type: FETCH_SHIPPING_FEE, result: [] });
  };
};
