import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, Link } from 'react-router-dom';

import ProfileDropdown from './ProfileDropdown';
import { APP_NAME } from '../constants/env';
import { MENUS } from '../constants/menu';

const sidebarLogo = require('../assets/img/bstore.png');

export default function Sidebar() {
  const { result: authenticatedUser } = useSelector((state) => state.authenticatedUser);
  const permissions = authenticatedUser.role.permissions
    ? authenticatedUser.role.permissions.split(', ')
    : null;

  const location = useLocation();

  const [collapseShow, setCollapseShow] = useState('hidden');

  return (
    <>
      <nav className="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-no-wrap md:overflow-hidden shadow-xl bg-white flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4 px-6 bg-white">
        <div className="md:flex-col md:items-stretch md:min-h-full md:flex-no-wrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
          {/* Toggler (Mobile Left Icon) */}
          <button
            className="cursor-pointer md:hidden px-1 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
            type="button"
            onClick={() => setCollapseShow('bg-white m-2 py-3 px-6')}>
            <i className="fas fa-bars"></i>
          </button>
          {/* Brand */}
          <Link className="md:flex md:mt-2 justify-center" to="/">
            <img src={sidebarLogo} alt={APP_NAME} className="h-10" />
          </Link>
          {/* Profile (Mobile Right Icon) */}
          <ul className="md:hidden items-center flex flex-wrap list-none">
            <li className="inline-block relative">
              <ProfileDropdown />
            </li>
          </ul>

          {/* Collapse */}
          <div
            className={`md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 pb-5 md:shadow-none
              shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center
              flex-1 rounded ${collapseShow}`}>
            {/* Collapse Header */}
            <div className="md:min-w-full md:hidden block md:pb-4 md:mb-4">
              <div className="flex flex-wrap">
                <div className="w-6/12">
                  <Link
                    className="md:block text-left md:pb-2 text-gray-700 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
                    to="/">
                    {APP_NAME}
                  </Link>
                </div>
                <div className="w-6/12 flex justify-end">
                  <button
                    type="button"
                    className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                    onClick={() => setCollapseShow('hidden')}>
                    <i className="fas fa-times"></i>
                  </button>
                </div>
              </div>
            </div>

            {/* Form */}
            {/* <form className="mt-6 mb-4 md:hidden">
              <div className="mb-3 pt-0">
                <input
                  type="text"
                  placeholder="Search"
                  className="px-3 py-2 h-12 border border-solid  border-gray-600 placeholder-gray-400 text-gray-700 bg-white rounded text-base leading-snug shadow-none outline-none focus:outline-none w-full font-normal"
                />
              </div>
            </form> */}

            {/* Menus */}
            <div className="md:mt-2">
              {MENUS.filter(
                (menu) =>
                  menu.childs.filter(
                    (child) => permissions === null || permissions.indexOf(child.id) >= 0,
                  ).length > 0,
              ).map((menu) => (
                <div key={menu.name}>
                  {/* Divider */}
                  <hr className="md:min-w-full" />

                  {/* Heading */}
                  <h6 className="block pt-4 pb-2 md:min-w-full text-gray-800 text-xs uppercase font-bold no-underline">
                    {menu.name}
                  </h6>

                  {/* Navigation */}
                  <ul className="mb-4 md:flex-col md:min-w-full flex flex-col list-none">
                    {menu.childs
                      .filter((child) => permissions === null || permissions.indexOf(child.id) >= 0)
                      .map((child) => (
                        <Link key={child.name} to={child.route}>
                          <li
                            className={`flex flex-row px-3 py-2 items-center
                                    rounded-md hover:bg-purple-600 transition-all
                                    text-xs uppercase hover:text-white
                                    ${
                                      location.pathname === child.route
                                        ? 'bg-purple-600 text-white'
                                        : 'text-gray-500'
                                    }`}>
                            {child.icon}
                            {` ${child.name}`}
                          </li>
                        </Link>
                      ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}
