import React, { forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import { Dialog } from '@headlessui/react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import {
  Modal,
  InlineTextInput,
  InlineSelect,
  BasicButton,
  SubmitButton,
} from '../../../components/Base';
import { MENUS } from '../../../constants/menu';
import { submitRole, updateRole } from '../../../actions/role';

function RoleCreate(props, ref) {
  const { register, errors, handleSubmit, setValue } = useForm();

  const [isVisible, setIsVisible] = useState(false);
  const [initialRole, setInitialRole] = useState(null);
  const [permissionType, setPermissionType] = useState('all');
  const [permissions, setPermissions] = useState([]);
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);

  const dispatch = useDispatch();

  useImperativeHandle(ref, () => ({
    showForm,
    showFormWithInitialData,
  }));

  useEffect(() => {
    if (initialRole) {
      setTimeout(() => {
        setValue('name', initialRole.name);

        console.log(initialRole);

        setPermissionType(initialRole.permissions ? 'custom' : 'all');
        if (initialRole.permissions) setPermissions(initialRole.permissions.split(', '));
      }, 100);
    }
  }, [initialRole]);

  const showForm = () => setIsVisible(true);
  const closeForm = () => {
    setIsVisible(false);
    setInitialRole(null);
    setIsSubmittingForm(false);
  };

  const showFormWithInitialData = (data) => {
    setInitialRole(data);
    showForm();
  };

  const onTogglePermission = (id) => {
    const newPermissions = [...permissions];
    if (newPermissions.indexOf(id) >= 0) newPermissions.splice(newPermissions.indexOf(id), 1);
    else newPermissions.push(id);
    setPermissions(newPermissions);
  };

  const onSubmitForm = (data) => {
    setIsSubmittingForm(true);

    // Set Permissions
    data.permissions = permissionType === 'all' ? null : permissions.join(', ');

    if (initialRole) {
      dispatch(updateRole(initialRole.id, data))
        .then(() => closeForm())
        .catch(() => setIsSubmittingForm(false));
    } else {
      dispatch(submitRole(data))
        .then(() => closeForm())
        .catch(() => setIsSubmittingForm(false));
    }
  };

  return (
    <Modal
      isVisible={isVisible}
      ModalContent={
        <div className="sm:flex sm:items-start">
          <div className="w-full mt-3 text-center sm:mt-0 sm:text-left">
            <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
              {initialRole ? 'Ubah Role' : 'Tambah Role Baru'}
            </Dialog.Title>
            <div className="mt-5">
              <form id="createForm" onSubmit={handleSubmit(onSubmitForm)}>
                <InlineTextInput
                  type="text"
                  title="Nama Role"
                  inputRef={register({ required: true })}
                  name="name"
                  errorMessage={errors.name && 'Kolom Nama Role harus diisi'}
                />

                <InlineSelect
                  title="Tipe Permission"
                  value={permissionType}
                  options={[
                    <option key="1" value="all">
                      All Permissions
                    </option>,
                    <option key="0" value="custom">
                      Custom
                    </option>,
                  ]}
                  onChange={(e) => setPermissionType(e.target.value)}
                />

                {permissionType === 'custom' && (
                  <div className="relative w-full mb-5">
                    <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-1 md:gap-4">
                      <div>
                        <label className="block uppercase text-gray-700 text-xs font-bold">
                          Permissions
                        </label>
                      </div>

                      <div className="col-span-2 xl:col-span-3">
                        {MENUS.map((menu) => (
                          <div key={menu.name}>
                            <label className="block uppercase text-gray-900 text-xs font-bold">
                              {menu.name}
                            </label>
                            <div className="mb-4">
                              {menu.childs.map((child) => (
                                <div key={child.id} className="mt-1">
                                  <label>
                                    <input
                                      type="checkbox"
                                      checked={permissions.indexOf(child.id) >= 0}
                                      onChange={() => onTogglePermission(child.id)}
                                    />
                                    {`  ${child.name}`}
                                  </label>
                                </div>
                              ))}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      }
      ModalButton={
        <>
          <SubmitButton
            type="submit"
            form="createForm"
            text="Submit"
            textClass="text-white text-xs"
            isLoading={isSubmittingForm}
          />
          <BasicButton text="Cancel" textClass="text-white text-xs mr-2" onClick={closeForm} />
        </>
      }
    />
  );
}

export default forwardRef(RoleCreate);
