import {
  FETCH_WHATSAPP_TEMPLATE,
  SUBMIT_WHATSAPP_TEMPLATE,
  FETCH_WHATSAPP_QR,
  RESET_STORE,
} from '../actions/_types';

const initialState = {
  result: {},
  submitResult: {},
  qrResult: {},
};

export default function whatsapp(state = initialState, action) {
  switch (action.type) {
    case FETCH_WHATSAPP_TEMPLATE:
      return {
        ...state,
        result: action.result,
      };

    case SUBMIT_WHATSAPP_TEMPLATE:
      return {
        ...state,
        submitResult: action.result,
      };

    case FETCH_WHATSAPP_QR:
      return {
        ...state,
        qrResult: action.result,
      };

    case RESET_STORE:
      return { ...initialState };

    default:
      return state;
  }
}
