import {
  FETCH_DASHBOARD_ORDER_SUMMARIES,
  FETCH_DASHBOARD_ORDER_STATISTICS,
} from '../actions/_types';

const initialState = {
  orderSummaries: {},
  orderStatistics: [],
};

export default function order(state = initialState, action) {
  switch (action.type) {
    case FETCH_DASHBOARD_ORDER_SUMMARIES:
      return {
        ...state,
        orderSummaries: { ...action.result },
      };

    case FETCH_DASHBOARD_ORDER_STATISTICS:
      return {
        ...state,
        orderStatistics: [...action.result],
      };

    default:
      return state;
  }
}
