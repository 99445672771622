import React, { forwardRef, useImperativeHandle, useState } from 'react';

import { Spinner, Modal, BasicButton } from '../../../components/Base';
import Utils from '../../../helpers/utils';
import PaymentTable from './PaymentTable';
import ShippingTable from './ShippingTable';
import ProductTable from './ProductTable';

function OrderDetail(props, ref) {
  // const { result: orders } = useSelector(state => state.order);

  const [isVisible, setIsVisible] = useState(false);
  const [order, setOrder] = useState(null);

  /* useEffect(() => {
    if (order) {
      const newOrder = orders.find(o => o.id === order.id);
      setOrder(newOrder);
    }
  }, [orders]); */

  useImperativeHandle(ref, () => ({ show }));

  const show = (data) => {
    setOrder(data);
    setIsVisible(true);
  };

  const close = () => {
    setIsVisible(false);
    setOrder(null);
  };

  return (
    <Modal
      isVisible={isVisible}
      size="xlarge"
      onClose={close}
      ModalContent={
        order ? (
          <div className="w-full text-left">
            <div className="flex">
              <div className="flex-1 text-lg text-left">
                <h2 className="font-bold">{`Order #${order.order_no}`}</h2>
                <div>
                  <span className="font-bold">Order Time </span>
                  <span>{order.order_time}</span>
                </div>
              </div>
              <div
                className="flex-1 text-right"
                dangerouslySetInnerHTML={{ __html: Utils.generateOrderStatus(order) }}></div>
            </div>

            {order.is_paid === 0 && (
              <div className="mt-5">
                <PaymentTable order={order} />
              </div>
            )}
            <div className="mt-5">
              <ShippingTable order={order} />
            </div>
            <div className="mt-5">
              <ProductTable order={order} />
            </div>
            {order.is_paid === 1 && (
              <div className="mt-5">
                <PaymentTable order={order} />
              </div>
            )}
          </div>
        ) : (
          <div className="flex justify-center">
            <Spinner />
          </div>
        )
      }
      ModalButton={<BasicButton text="Close" textClass="text-white text-xs mr-2" onClick={close} />}
    />
  );
}

export default forwardRef(OrderDetail);
