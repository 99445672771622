import React, { useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useSelector } from 'react-redux';

import $ from 'jquery';

import ReviewCreate from './Create';
import { SubmitButton } from '../../components/Base';
import { API_URL, DATATABLES_DEFAULT_CONFIG } from '../../constants/env';
// import { deleteReview } from '../../../actions/review';

export default function Review() {
  const reviewFormRef = useRef();

  const { result: authenticatedUser } = useSelector((state) => state.authenticatedUser);
  const { refresh } = useSelector((state) => state.review);
  // const dispatch = useDispatch();

  useEffect(() => {
    initializeDataTables();
  }, [refresh]);

  const initializeDataTables = () => {
    $('#reviewDataTable').DataTable().destroy();
    $('#reviewDataTable').DataTable({
      ...DATATABLES_DEFAULT_CONFIG,
      ajax: {
        url: `${API_URL}/review?source=datatables`,
        dataSrc: 'data',
        beforeSend: (xhr) =>
          xhr.setRequestHeader('Authorization', `Bearer ${authenticatedUser.token}`),
      },
      columns: [
        { data: 'order.order_no' },
        { data: 'product.name' },
        {
          data: 'star',
          render: (data) => `<span class="text-purple-600">★</span>${data}`,
        },
        { data: 'review' },
        {
          data: 'is_public',
          render: (data) => (data === 1 ? '✓' : ''),
        },
        {
          data: 'id',
          searchable: false,
          sortable: false,
          createdCell: (cell, cellData, rowData) =>
            ReactDOM.render(
              <div>
                <SubmitButton
                  color="blue"
                  textClass="text-white text-xs"
                  paddingClass="px-3 py-2 mr-1"
                  text="Edit"
                  onClick={() => onPressEdit(rowData)}
                />
                {/* <DeleteButton
                color="red"
                textClass="text-white text-xs"
                paddingClass="px-3 py-2"
                onClick={() => onPressDelete(rowData)}
              /> */}
              </div>,
              cell,
            ),
        },
      ],
    });
  };

  // const onPressCreate = () => reviewFormRef.current.showForm();
  const onPressEdit = (data) => reviewFormRef.current.showFormWithInitialData(data);
  // const onPressDelete = data => dispatch(deleteReview(data.id));

  return (
    <>
      <div className="relative flex flex-col min-w-0 w-full h-full bg-white mb-8 shadow-lg rounded-xl">
        {/* Header */}
        <div className="flex flex-wrap items-center py-3 border-b">
          <div className="px-4 flex-1">
            <h3 className="font-semibold text-gray-800 pl-2">Review</h3>
          </div>
          <div className="px-4">
            {/* <SubmitButton
              textClass="text-white text-xs"
              text="Tambah Review"
              onClick={onPressCreate}
            /> */}
            <ReviewCreate ref={reviewFormRef} />
          </div>
        </div>

        {/* Table Content */}
        <div className="block w-full overflow-x-auto p-6">
          {/* Projects table */}
          <table
            id="reviewDataTable"
            className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-semibold text-left">
                  No Order
                </th>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-semibold text-left">
                  Nama Produk
                </th>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-semibold text-left">
                  Star
                </th>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-semibold text-left w-60">
                  Review
                </th>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-semibold text-left">
                  Public
                </th>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-semibold text-left w-40"></th>
              </tr>
            </thead>
            <tbody></tbody>
          </table>
        </div>
      </div>
    </>
  );
}
