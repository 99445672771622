import React, { forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import { Dialog } from '@headlessui/react';
import ReactDatePicker from 'react-datepicker';
import { useForm, Controller } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import Swal from 'sweetalert2';

import {
  Modal,
  InlineTextInput,
  InlineSelect,
  InlineSwitch,
  BasicButton,
  SubmitButton,
} from '../../../components/Base';

import { fetchPaymentMethod } from '../../../actions/paymentMethod';
import { submitVoucher, updateVoucher } from '../../../actions/voucher';

function VoucherCreate(props, ref) {
  const { register, errors, handleSubmit, control, setValue } = useForm();

  const [isVisible, setIsVisible] = useState(false);
  const [initialVoucher, setInitialVoucher] = useState(null);
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);

  const { result: paymentMethods } = useSelector((state) => state.paymentMethod);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPaymentMethod());
  }, []);

  //

  useImperativeHandle(ref, () => ({
    showForm,
    showFormWithInitialData,
  }));

  const showForm = () => setIsVisible(true);
  const closeForm = () => {
    setIsVisible(false);
    setInitialVoucher(null);
    setIsSubmittingForm(false);
  };

  const showFormWithInitialData = (data) => {
    setInitialVoucher(data);
    showForm();
  };

  //

  const onSubmitForm = (data) => {
    if (data.end_date <= data.start_date) {
      Swal.fire('Submit Error', 'Tanggal Selesai tidak boleh dibawah Tanggal Mulai', 'error');
      return;
    }

    setIsSubmittingForm(true);

    data.voucher_code = data.voucher_code.toUpperCase();
    data.start_date = moment(data.start_date).format('YYYY-MM-DD');
    data.end_date = moment(data.end_date).format('YYYY-MM-DD');
    data.status = +data.status;

    if (initialVoucher) {
      dispatch(updateVoucher(initialVoucher.id, data))
        .then(() => closeForm())
        .catch(() => setIsSubmittingForm(false));
    } else {
      dispatch(submitVoucher(data))
        .then(() => closeForm())
        .catch(() => setIsSubmittingForm(false));
    }
  };

  return (
    <Modal
      isVisible={isVisible}
      size="large"
      ModalContent={
        <div className="sm:flex sm:items-start">
          <div className="w-full mt-3 text-center sm:mt-0 sm:text-left">
            <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
              {initialVoucher ? 'Ubah Voucher' : 'Tambah Voucher Baru'}
            </Dialog.Title>
            <div className="mt-5">
              <form id="createForm" onSubmit={handleSubmit(onSubmitForm)}>
                <InlineTextInput
                  type="text"
                  title="Kode Voucher*"
                  inputRef={register({ required: true })}
                  name="voucher_code"
                  defaultValue={initialVoucher?.voucher_code}
                  errorMessage={errors.voucher_code && 'Kolom Kode Voucher harus diisi'}
                />

                <InlineSelect
                  title="Metode Pembayaran"
                  inputRef={register()}
                  name="payment_method_id"
                  options={paymentMethods.map((p) => (
                    <option key={p.id} value={p.id}>
                      {p.name}
                    </option>
                  ))}
                  defaultValue={initialVoucher?.payment_method_id}
                />

                <div className="relative w-full mb-5">
                  <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-1 md:gap-4 items-center">
                    <div>
                      <label className="block uppercase text-gray-700 text-xs font-bold">
                        Tanggal Mulai*
                      </label>
                    </div>
                    <div className="col-span-2 xl:col-span-3">
                      <div className="relative flex flex-wrap items-stretch w-1/2">
                        <Controller
                          control={control}
                          rules={{ required: true }}
                          name="start_date"
                          defaultValue={
                            initialVoucher ? new Date(initialVoucher.start_date) : new Date()
                          }
                          render={({ onChange, onBlur, value }) => (
                            <div className="px-3 py-3 text-sm text-gray-700 bg-white rounded shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150">
                              <ReactDatePicker
                                onChange={onChange}
                                onBlur={onBlur}
                                selected={value}
                                dateFormat="yyyy-MM-dd"
                              />
                            </div>
                          )}
                        />
                      </div>
                      {errors.start_date && (
                        <span className="text-xs text-red-500">
                          Kolom Tanggal Mulai harus diisi
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="relative w-full mb-5">
                  <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-1 md:gap-4 items-center">
                    <div>
                      <label className="block uppercase text-gray-700 text-xs font-bold">
                        Tanggal Selesai*
                      </label>
                    </div>
                    <div className="col-span-2 xl:col-span-3">
                      <div className="relative flex flex-wrap items-stretch w-1/2">
                        <Controller
                          control={control}
                          rules={{ required: true }}
                          name="end_date"
                          defaultValue={
                            initialVoucher ? new Date(initialVoucher.end_date) : new Date()
                          }
                          render={({ onChange, onBlur, value }) => (
                            <div className="px-3 py-3 text-sm text-gray-700 bg-white rounded shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150">
                              <ReactDatePicker
                                onChange={onChange}
                                onBlur={onBlur}
                                selected={value}
                                dateFormat="yyyy-MM-dd"
                              />
                            </div>
                          )}
                        />
                      </div>
                      {errors.end_date && (
                        <span className="text-xs text-red-500">
                          Kolom Tanggal Selesai harus diisi
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                <InlineSelect
                  inputWidth="w-1/2"
                  title="Tipe Discount*"
                  inputRef={register({ required: true })}
                  name="discount_type"
                  options={[
                    <option key={0} value="Fixed Amount">
                      Jumlah Tetap (Rp)
                    </option>,
                    <option key={1} value="Percentage">
                      Persentase (%)
                    </option>,
                  ]}
                  defaultValue={initialVoucher?.discount_type}
                  errorMessage={errors.discount_type && 'Kolom Tipe Discount harus dipilih'}
                />

                <InlineTextInput
                  inputWidth="w-1/2"
                  type="number"
                  title="Jumlah Discount*"
                  inputRef={register({ required: true })}
                  name="discount_amount"
                  defaultValue={initialVoucher?.discount_amount}
                  errorMessage={errors.discount_amount && 'Kolom Jumlah Discount harus diisi'}
                />

                <InlineTextInput
                  inputWidth="w-1/2"
                  type="number"
                  title="Max Discount*"
                  inputRef={register({ required: true })}
                  name="max_discount"
                  defaultValue={initialVoucher?.max_discount}
                  errorMessage={errors.max_discount && 'Kolom Max Discount harus diisi'}
                />

                <InlineTextInput
                  inputWidth="w-1/2"
                  type="number"
                  title="Min Order*"
                  inputRef={register({ required: true })}
                  name="min_order"
                  defaultValue={initialVoucher?.min_order}
                  errorMessage={errors.min_order && 'Kolom Min Order harus diisi'}
                />

                <InlineTextInput
                  inputWidth="w-1/2"
                  type="number"
                  title="Quota*"
                  inputRef={register({ required: true })}
                  name="quota"
                  defaultValue={initialVoucher?.quota}
                  errorMessage={errors.quota && 'Kolom Quota harus diisi'}
                />

                <InlineSwitch
                  title="Status"
                  inputRef={register()}
                  name="status"
                  defaultChecked={initialVoucher && Number(initialVoucher.status) === 1}
                />
              </form>
            </div>
          </div>
        </div>
      }
      ModalButton={
        <>
          <SubmitButton
            type="submit"
            form="createForm"
            text="Submit"
            textClass="text-white text-xs"
            isLoading={isSubmittingForm}
          />
          <BasicButton text="Cancel" textClass="text-white text-xs mr-2" onClick={closeForm} />
        </>
      }
    />
  );
}

export default forwardRef(VoucherCreate);
