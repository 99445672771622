import { combineReducers } from 'redux';

import authenticatedUser from './authenticatedUser';
import auth from './auth';
import password from './password';
import profile from './profile';

import rajaongkir from './rajaongkir';

import filter from './filter';
import dashboard from './dashboard';
import order from './order';

import phoneService from './phoneService';
import technician from './technician';

import product from './product';
import productCategory from './productCategory';

import review from './review';

import voucher from './voucher';

import page from './page';

import slider from './slider';
import admin from './admin';
import role from './role';
import paymentMethod from './paymentMethod';
import shippingMethod from './shippingMethod';
import warehouse from './warehouse';
import whatsapp from './whatsapp';
import settings from './settings';

export default combineReducers({
  authenticatedUser,
  auth,
  password,
  profile,

  rajaongkir,

  filter,
  dashboard,
  order,

  phoneService,
  technician,

  product,
  productCategory,
  review,

  voucher,

  page,

  slider,
  admin,
  role,
  paymentMethod,
  shippingMethod,
  warehouse,
  whatsapp,
  settings,
});
