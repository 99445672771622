import {
  FETCH_ROLE,
  SUBMIT_ROLE,
  DELETE_ROLE,
  REFRESH_ROLE_DATATABLES,
  RESET_STORE,
} from '../actions/_types';

const initialState = {
  result: [],
  submitResult: {},
  deleteResult: {},

  refresh: false,
};

export default function role(state = initialState, action) {
  switch (action.type) {
    case FETCH_ROLE:
      return {
        ...state,
        result: action.result,
      };

    case SUBMIT_ROLE:
      return {
        ...state,
        submitResult: action.result,
      };

    case DELETE_ROLE:
      return {
        ...state,
        deleteResult: action.result,
      };

    case REFRESH_ROLE_DATATABLES:
      return {
        ...state,
        refresh: !state.refresh,
      };

    case RESET_STORE:
      return { ...initialState };

    default:
      return state;
  }
}
