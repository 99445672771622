import React, { useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useSelector, useDispatch } from 'react-redux';

import $ from 'jquery';

import PhoneServiceCreate from './Create';
import PhoneServiceDetail from './Detail';
import { DeleteButton, SubmitButton } from '../../components/Base';
import { API_URL, DATATABLES_DEFAULT_CONFIG } from '../../constants/env';
import { SERVICE_STATUS_STRING } from '../../constants/data';
import { deletePhoneService } from '../../actions/phoneService';

export default function PhoneService() {
  const phoneServiceFormRef = useRef();
  const phoneServiceDetailRef = useRef();

  const { result: authenticatedUser } = useSelector((state) => state.authenticatedUser);
  const { refresh } = useSelector((state) => state.phoneService);
  const dispatch = useDispatch();

  useEffect(() => {
    initializeDataTables();
  }, [refresh]);

  const initializeDataTables = () => {
    $('#phoneServiceDataTable').DataTable().destroy();
    $('#phoneServiceDataTable').DataTable({
      ...DATATABLES_DEFAULT_CONFIG,
      ajax: {
        url: `${API_URL}/phone-service?source=datatables&active=true`,
        dataSrc: 'data',
        beforeSend: (xhr) =>
          xhr.setRequestHeader('Authorization', `Bearer ${authenticatedUser.token}`),
      },
      columns: [
        { data: 'service_no' },
        { data: 'time' },
        { data: 'pic' },
        {
          data: 'customer_name',
          render: (data, type, row) => `${data}<br />(Ph: ${row.customer_phone})`,
        },
        {
          data: 'status',
          render: (data) => SERVICE_STATUS_STRING[data],
        },
        {
          data: 'id',
          searchable: false,
          sortable: false,
          createdCell: (cell, cellData, rowData) =>
            ReactDOM.render(
              <div>
                <SubmitButton
                  color="green"
                  textClass="text-white text-xs"
                  paddingClass="px-3 py-2 mr-1"
                  text="View"
                  onClick={() => onPressView(rowData)}
                />
                <SubmitButton
                  color="blue"
                  textClass="text-white text-xs"
                  paddingClass="px-3 py-2 mr-1"
                  text="Edit"
                  onClick={() => onPressEdit(rowData)}
                />
                <DeleteButton
                  color="red"
                  textClass="text-white text-xs"
                  paddingClass="px-3 py-2"
                  onClick={() => onPressDelete(rowData)}
                />
              </div>,
              cell,
            ),
        },
      ],
    });
  };

  const onPressCreate = () => phoneServiceFormRef.current.showForm();
  const onPressView = (data) => phoneServiceDetailRef.current.show(data);
  const onPressEdit = (data) => phoneServiceFormRef.current.showFormWithInitialData(data);
  const onPressDelete = (data) => dispatch(deletePhoneService(data.id));

  return (
    <>
      <div className="relative flex flex-col min-w-0 w-full h-full bg-white mb-8 shadow-lg rounded-xl">
        {/* Header */}
        <div className="flex flex-wrap items-center py-3 border-b">
          <div className="px-4 flex-1">
            <h3 className="font-semibold text-gray-800 pl-2">Phone Service</h3>
          </div>
          <div className="px-4">
            <SubmitButton
              textClass="text-white text-xs"
              text="Tambah Service"
              onClick={onPressCreate}
            />
            <PhoneServiceCreate ref={phoneServiceFormRef} />
            <PhoneServiceDetail ref={phoneServiceDetailRef} />
          </div>
        </div>

        {/* Table Content */}
        <div className="block w-full overflow-x-auto p-6">
          {/* Projects table */}
          <table
            id="phoneServiceDataTable"
            className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-semibold text-left">
                  Service No
                </th>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-semibold text-left">
                  Time
                </th>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-semibold text-left">
                  PIC
                </th>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-semibold text-left">
                  Customer
                </th>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-semibold text-left">
                  Status
                </th>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-semibold text-left w-48"></th>
              </tr>
            </thead>
            <tbody></tbody>
          </table>
        </div>
      </div>
    </>
  );
}
