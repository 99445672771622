import moment from 'moment';
import { FILTER_BY_DATE, FILTER_BY_WAREHOUSE } from '../actions/_types';

const initialState = {
  filterDate: {
    startDate: moment().format('YYYY-MM-DD 00:00:00'),
    endDate: moment().format('YYYY-MM-DD 23:59:59'),
  },
  filterWarehouse: null,
};

export default function outlet(state = initialState, action) {
  switch (action.type) {
    case FILTER_BY_DATE:
      return {
        ...state,
        filterDate: action.result,
      };

    case FILTER_BY_WAREHOUSE:
      return {
        ...state,
        filterWarehouse: action.result,
      };

    default:
      return state;
  }
}
